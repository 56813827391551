import { useContext, useEffect, useState } from 'react';
import { GlobalContext } from '../context/GlobalContext';
import { ContainerSpinner } from '../components/Spinner';
import { Edit, Hash, Info, Landmark, PlusCircle, Smartphone, UserCircle2 } from 'lucide-react';
import useFetch from '../hooks/api';
import { ucfirst } from '../utils/formatters';
import ErrorRedirectDialog from '../components/ErrorRedirectDialog';
import { Dialog } from '../components/Dialog';
import Input from '../components/Input';
import { Button } from '../shadcn/Button';
import Select from '../components/Select';
import { validate_rif } from '../utils/validate';
import firestore from '../firestore';
import { Navigate } from 'react-router-dom';

export default function MetodosDePago() {
	const { userData } = useContext(GlobalContext);
	const { master_store } = userData!;

	if (userData?.country !== 'venezuela' || userData?.financed_by_krece || !userData?.master_user) Navigate({ to: '/' });

	const banks = GetBanks();

	const { data, loading, error } = useFetch('https://api.krece.app/storeprofile/store_payment_methods/', 'GET', true, {
		email: master_store.id
	});

	if (data?.pagomovil)
		data.pagomovil = {
			bank: data.pagomovil?.bank || '',
			phoneNumber: data.pagomovil?.phoneNumber || '',
			rif: data.pagomovil?.rif || ''
		};

	if (data?.transferBs)
		data.transferBs = {
			account: data.transferBs?.account || '',
			titular: data.transferBs?.titular || '',
			type: data.transferBs?.type || '',
			bank: data.transferBs?.bank || '',
			rif: data.transferBs?.rif || ''
		};

	const [transferBs, setTransferBs] = useState<NonNullable<typeof data>['transferBs'] | null>(null);
	const [pagomovil, setPagomovil] = useState<NonNullable<typeof data>['pagomovil'] | null>(null);

	const EditTransferenciaDialog = () => {
		const [submit, setSubmit] = useState(false);
		const { loading, error, setPayload, fetched } = useFetch(
			'https://api.krece.app/storeprofile/store_payment_methods/?PUT',
			'PUT',
			submit
		);

		const [validationError, setValidationError] = useState(false);
		const accNumberValidation = (value: string) => value.trim().length === 20;
		const accNameValidation = (value: string) => value.trim().length >= 4;

		function submitChanges() {
			if (!transferBs) return;

			if (
				!accNumberValidation(transferBs.account) ||
				!accNameValidation(transferBs.titular) ||
				!validate_rif(transferBs.rif) ||
				!transferBs.type
			) {
				setValidationError(true);
			} else {
				setPayload({
					master_store_email: master_store.id,
					...(data && data),
					...(data?.pagomovil && { pagomovil: { ...data.pagomovil, rif: transferBs.rif, bank: transferBs.bank } }),
					transferBs
				});
				setSubmit(true);
			}
		}

		useEffect(() => {
			if (fetched && !error) {
				// setTransferBs(null);
				// refetch();
				window.location.reload();
			}
		}, [fetched, error]);

		if (!transferBs) return null;

		return (
			<>
				{validationError && (
					<Dialog title="¡Error!" onClose={() => setValidationError(false)} zIndex={51}>
						<p>Los siguientes campos están vacíos o son inválidos:</p>
						<ul className="text-red-400">
							{!accNameValidation(transferBs.titular) && <li className="list-inside list-disc">Titular</li>}
							{!accNumberValidation(transferBs.account) && <li className="list-inside list-disc">Número de Cuenta</li>}
							{!transferBs.type && <li className="list-inside list-disc">Tipo de Cuenta</li>}
							{!validate_rif(transferBs.rif) && <li className="list-inside list-disc">RIF</li>}
						</ul>
					</Dialog>
				)}
				{error && (
					<Dialog title="¡Error!" zIndex={52}>
						<p>{typeof error === 'string' ? error : 'Ha ocurrido un error. Intente más tarde.'}</p>
					</Dialog>
				)}
				<Dialog title="Transferencia" closable={false}>
					{loading && (
						<div className="absolute inset-0 z-50 flex h-full w-full items-center justify-center rounded-xl bg-black/70">
							<ContainerSpinner size={2.75} />
						</div>
					)}
					<form className="space-y-6" onSubmit={(e) => e.preventDefault()}>
						<div className="flex flex-col gap-3">
							<Input
								allowedChars="alphanumeric"
								maxLength={255}
								placeholder="Titular..."
								value={transferBs.titular}
								Icon={<UserCircle2 className="size-4" />}
								validation={accNameValidation}
								onChange={(value) => (transferBs.titular = value.trim())}
							/>
							<Select
								className="h-12 w-full bg-neutral-950"
								options={banks.map((e) => ({ label: e, value: e }))}
								defaultValue={transferBs.bank || undefined}
								onSelectedChange={(value) => (transferBs.bank = typeof value === 'string' ? value.trim() : '')}
								Icon={<Landmark className="size-4" />}
								placeholder="Banco..."
							/>
							<p className="-mt-2 flex items-center gap-2 text-[0.8rem] text-neutral-300">
								<Info className="size-3.5 shrink-0 text-neutral-500" />
								El banco también será modificado en Pago Móvil
							</p>
							<div className="flex flex-col gap-3 sm:flex-row sm:gap-2">
								<div className="w-full">
									<Input
										allowedChars="numbers_only"
										maxLength={255}
										placeholder="Número de Cuenta..."
										value={transferBs.account}
										Icon={<Hash className="size-4" />}
										validation={accNumberValidation}
										onChange={(value) => (transferBs.account = value.trim())}
									/>
								</div>
								<div className="w-full shrink-0 sm:w-auto">
									<Select
										className="h-12 w-full bg-neutral-950 sm:w-auto"
										options={['corriente', 'ahorro'].map((e) => ({ label: ucfirst(e), value: e }))}
										defaultValue={transferBs.type || undefined}
										onSelectedChange={(value) => (transferBs.type = typeof value === 'string' ? value.trim() : '')}
										placeholder="Tipo"
									/>
								</div>
							</div>
							<Input
								maxLength={255}
								placeholder="Ingresar..."
								value={transferBs.rif}
								Icon={<span className="font-bold tracking-tight">RIF</span>}
								validation={validate_rif}
								transformation={(value) => value.toUpperCase()}
								onChange={(value) => (transferBs.rif = value.trim().toUpperCase())}
							/>
							<p className="-mt-2 flex items-center gap-2 text-[0.8rem] text-neutral-300">
								<Info className="size-3.5 shrink-0 text-neutral-500" />
								El RIF también será modificado en Pago Móvil
							</p>
						</div>
						<div className="flex justify-end gap-2">
							<Button variant="default" className="bg-neutral-900 hover:bg-neutral-900/70" onClick={() => setTransferBs(null)}>
								Cancelar
							</Button>
							<Button type="submit" variant="default" className="bg-sky-600 hover:bg-sky-800" onClick={submitChanges}>
								Confirmar
							</Button>
						</div>
					</form>
				</Dialog>
			</>
		);
	};

	const EditPagoMovilDialog = () => {
		const [submit, setSubmit] = useState(false);
		const { loading, error, setPayload, fetched } = useFetch(
			'https://api.krece.app/storeprofile/store_payment_methods/?PUT',
			'PUT',
			submit
		);

		const [validationError, setValidationError] = useState(false);
		const phoneValidation = (value: string) => value.trim().length >= 7;

		function submitChanges() {
			if (!pagomovil) return;

			if (!phoneValidation(pagomovil.phoneNumber) || !validate_rif(pagomovil.rif)) {
				setValidationError(true);
			} else {
				setPayload({
					master_store_email: master_store.id,
					...(data && data),
					...(data?.transferBs && { transferBs: { ...data.transferBs, rif: pagomovil.rif, bank: pagomovil.bank } }),
					pagomovil
				});
				setSubmit(true);
			}
		}

		useEffect(() => {
			if (fetched && !error) {
				// setPagomovil(null);
				// refetch();
				window.location.reload();
			}
		}, [fetched, error]);

		if (!pagomovil) return null;

		return (
			<>
				{validationError && (
					<Dialog title="¡Error!" onClose={() => setValidationError(false)} zIndex={51}>
						<p>Los siguientes campos están vacíos o son inválidos:</p>
						<ul className="text-red-400">
							{!validate_rif(pagomovil.rif) && <li className="list-inside list-disc">RIF</li>}{' '}
							{!phoneValidation(pagomovil.phoneNumber) && <li className="list-inside list-disc">Número de Teléfono</li>}
						</ul>
					</Dialog>
				)}
				{error && (
					<Dialog title="¡Error!" zIndex={52}>
						<p>{typeof error === 'string' ? error : 'Ha ocurrido un error. Intente más tarde.'}</p>
					</Dialog>
				)}
				<Dialog title="Pago Móvil" closable={false}>
					{loading && (
						<div className="absolute inset-0 z-50 flex h-full w-full items-center justify-center rounded-xl bg-black/70">
							<ContainerSpinner size={2.75} />
						</div>
					)}
					<form className="space-y-6" onSubmit={(e) => e.preventDefault()}>
						<div className="flex flex-col gap-3">
							<Select
								className="h-12 w-full bg-neutral-950"
								options={banks.map((e) => ({ label: e, value: e }))}
								defaultValue={pagomovil.bank || undefined}
								onSelectedChange={(value) => (pagomovil.bank = typeof value === 'string' ? value.trim() : '')}
								Icon={<Landmark className="size-4" />}
								placeholder="Banco..."
							/>
							<p className="-mt-2 flex items-center gap-2 text-[0.8rem] text-neutral-300">
								<Info className="size-3.5 shrink-0 text-neutral-500" />
								El banco también será modificado en Transferencia
							</p>
							<Input
								allowedChars="numbers_only"
								maxLength={255}
								placeholder="Ingresar..."
								value={pagomovil.phoneNumber}
								Icon={<Smartphone className="size-4" />}
								validation={phoneValidation}
								onChange={(value) => (pagomovil.phoneNumber = value.trim())}
							/>
							<Input
								maxLength={255}
								placeholder="Ingresar..."
								value={pagomovil.rif}
								Icon={<span className="font-bold tracking-tight">RIF</span>}
								validation={validate_rif}
								transformation={(value) => value.toUpperCase()}
								onChange={(value) => (pagomovil.rif = value.trim().toUpperCase())}
							/>
							<p className="-mt-2 flex items-center gap-2 text-[0.8rem] text-neutral-300">
								<Info className="size-3.5 shrink-0 text-neutral-500" />
								El RIF también será modificado en Transferencia
							</p>
						</div>
						<div className="flex justify-end gap-2">
							<Button variant="default" className="bg-neutral-900 hover:bg-neutral-900/70" onClick={() => setPagomovil(null)}>
								Cancelar
							</Button>
							<Button type="submit" variant="default" className="bg-sky-600 hover:bg-sky-800" onClick={submitChanges}>
								Confirmar
							</Button>
						</div>
					</form>
				</Dialog>
			</>
		);
	};

	return (
		<>
			{loading && <ContainerSpinner size={3} />}
			{error && <ErrorRedirectDialog />}

			{data?.pagomovil && data?.transferBs && (
				<section className="mx-auto h-full w-full space-y-6 overflow-auto p-4 md:p-6">
					<div className="flex h-full flex-wrap content-center items-center justify-center gap-8 lg:mt-24 lg:h-auto">
						<article className="flex h-[13.5rem] w-[23.5rem] flex-col space-y-4 rounded-xl border-2 border-neutral-700 p-3">
							<header className="flex w-full shrink-0 items-center justify-between">
								<h1 className="flex w-full items-center gap-1 text-xl font-medium tracking-tight 3xl:text-2xl">
									<Smartphone className="size-5" />
									Pago Móvil
								</h1>
{/* 								<button
									className="shrink-0"
									onClick={() => setPagomovil(data.pagomovil || { phoneNumber: '', rif: '', bank: '' })}
								>
									<Edit />
								</button> */}
							</header>
							<div className="h-full">
								{data?.pagomovil?.phoneNumber ? (
									<div>
										<strong className="mb-6 block text-lg leading-none">{data?.pagomovil?.bank}</strong>
										<p className="mb-3 flex items-center gap-1 leading-none text-neutral-200/85">
											<Smartphone className="size-5 text-neutral-200" />
											{data?.pagomovil?.phoneNumber}
										</p>
										<p className="leading-none text-neutral-200/85">
											<span className="mr-1 font-bold tracking-tighter text-neutral-200">RIF</span>
											{data?.pagomovil?.rif}
										</p>
									</div>
								) : (
									<p
										onClick={() => setPagomovil(data.pagomovil || { phoneNumber: '', rif: '', bank: '' })}
										className="flex h-full w-full cursor-pointer items-center justify-center gap-2 text-xl font-medium text-neutral-400"
									>
										Asignar <PlusCircle className="size-6" />
									</p>
								)}
							</div>
						</article>

						<article className="flex h-[13.5rem] w-[23.5rem] flex-col space-y-4 rounded-xl border-2 border-neutral-700 p-3">
							<header className="flex w-full shrink-0 items-center justify-between">
								<h1 className="flex items-center gap-1 text-xl font-medium tracking-tight 3xl:text-2xl">
									<Landmark className="size-5" />
									Transferencia
								</h1>
{/* 								<button
									onClick={() => setTransferBs(data.transferBs || { rif: '', type: '', bank: '', account: '', titular: '' })}
								>
									<Edit />
								</button> */}
							</header>
							<div className="h-full">
								{data?.transferBs?.account ? (
									<div>
										<strong className="mb-3 block text-lg leading-none">{data?.transferBs?.bank}</strong>
										<div className="mb-6 flex items-center gap-2">
											<p className="font-mono leading-tight text-neutral-200/85">{data?.transferBs?.account}</p>
											<p className="rounded-lg bg-neutral-700 px-1.5 py-1 text-sm font-semibold leading-none">
												{ucfirst(data?.transferBs?.type)}
											</p>
										</div>
										<strong className="mb-1.5 block text-lg leading-none">{data?.transferBs?.titular}</strong>
										<p className="leading-none text-neutral-200/85">
											<span className="mr-1 font-bold tracking-tighter text-neutral-200">RIF</span>
											{data?.transferBs?.rif}
										</p>
									</div>
								) : (
									<p
										onClick={() => setTransferBs(data.transferBs || { rif: '', type: '', bank: '', account: '', titular: '' })}
										className="flex h-full w-full cursor-pointer items-center justify-center gap-2 text-xl font-medium text-neutral-400"
									>
										Asignar <PlusCircle className="size-6" />
									</p>
								)}
							</div>
						</article>
					</div>
				</section>
			)}

			{transferBs && <EditTransferenciaDialog />}
			{pagomovil && <EditPagoMovilDialog />}
		</>
	);
}

function GetBanks() {
	const [banks, setBanks] = useState<string[]>([]);

	useEffect(() => {
		(async () => {
			const document = (await firestore.collection('AppSetting').doc('CountrySettings').get()).data();

			if (document?.['venezuela']?.banks?.length) setBanks(document?.['venezuela']?.banks);
		})();
	}, []);

	return banks;
}
