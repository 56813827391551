
import React, { useState, useContext, useEffect } from "react";
import { Typography, Container, Button, TextField, makeStyles, MenuItem, CircularProgress } from '@material-ui/core';
import firebase from 'firebase/compat/app';
import { FaChevronDown, FaChevronUp, FaExclamationTriangle } from 'react-icons/fa'; // For icons
import 'firebase/compat/firestore';
import auth from '../auth'
import firestore from '../firestore'
import { useNavigate } from "react-router-dom";
import { ArrowBack, CheckCircle } from '@material-ui/icons';
import { GlobalContext } from '../context/GlobalContext';
import { BeatLoader } from 'react-spinners';
import * as XLSX from 'xlsx';

const Pay = () => {
    const classes = useStyles();

    const [page, setPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [cedula, setCedula] = useState('');
    const [formError, setFormError] = useState('');
    const [showTokenField, setShowTokenField] = useState(false);
    const [loadingButton, setLoadingButton] = useState(false);
    const [infoMessage, setInfoMessage] = useState('');
    const [payments, setPayments] = useState([]);
    const [amountLeftToPay, setAmountLeftToPay] = useState(0);
    const [email, setEmail] = useState('');
    const [token, setToken] = useState(process.env.REACT_APP_API_TOKEN);
    const [showPayments, setShowPayments] = useState({});
    const [paymentDocId, setPaymentDocId] = useState('');
    const [amountToPayDollars, setAmountToPayDollars] = useState('');
    const [paymentDocRef, setPaymentDocRef] = useState('');
    const [exchangeRate, setExchangeRate] = useState('');
    const [payMethod, setPayMethod] = useState('');
    const [confirmationNumber, setConfirmationNumber] = useState('');
    const [amountPaid, setAmountPaid] = useState('');
    const [clientName, setClientName] = useState('');
    const [paymentDate, setPaymentDate] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [bank, setBank] = useState('');
    const [pagoMovilData, setPagoMovilData] = useState({});
    const [transferData, setTransferData] = useState({});
    const [countrySettings, setCountrySettings] = useState({});
    const [admins, setAdmins] = useState({});
    const [selectedAdmin, setSelectedAdmin] = useState('');
    const [twofaNeeded, setTwoFaNeeded] = useState({});
    const [amountReceived, setAmountReceived] = useState('');
    const [subStore, setSubStore] = useState('');
    const [phoneToken, setPhoneToken] = useState('');
    const [subStoreList, setSubStoreList] = useState([]);
    const [tokenVerified, setTokenVerified] = useState(false);

    const { authenticated, userData } = useContext(GlobalContext);
    const country = userData.country
    const navigate = useNavigate();

    const bankCodesList = ['0102 - Banco de Venezuela (BDV)', '0104 - Banco Venezolano de Crédito (BVC)', '0105 - Banco Mercantil', '0108 - Banco Provincial (BBVA)', '0114 - Bancaribe', '0115 - Banco Exterior', '0128 - Banco Caroní', '0134 - Banesco Banco Universal', '0137 - Sofitasa', '0138 - Banco Plaza', '0146 - Bangente', '0151 - Banco Fondo Común (BFC)', '0156 - 100% Banco', '0157 - Del Sur Banco Universal', '0163 - Banco del Tesoro', '0166 - Banco Agrícola de Venezuela', '0168 - Bancrecer', '0169 - Mi Banco, Banco Microfinanciero C.A', '0171 - Banco Activo', '0172 - Bancamiga', '0174 - Banplus', '0175 - Banco Bicentenario del Pueblo', '0177 - Banco de la Fuerza Armada Nacional Bolivariana (BANFANB)', '0191 - Banco Nacional de Crédito (BNC)']


    // const firestoreKreceDev = firestore.database('krece-dev');
    // const firestoreDefault = firestore;

    const goToPage = (x) => {
        setInfoMessage('')
        setFormError('')
        setPage(x);
    }

    const formatNumber = (number) => {
        return new Intl.NumberFormat('en-US', {
            style: 'decimal',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        }).format(number);
    };

    const backToWelcomePage = () => {
        window.location.reload();
    };

    useEffect(() => {
        if (!authenticated) {
            navigate("/login");
        }

        try {
            if (!userData.master_user) {
                setSubStoreList([userData.store_name]);
            } else {
                setSubStoreList(userData.sub_stores);
            }
        } catch (err) {
            console.log(err)
            alert('Ocurrió un problema')
        }

        const getMasterStoreData = async () => {

            try {
                // Fetch all documents from the `Stores` collection and store their IDs in a Set
                const storesSnapshot = await firestore.collection('Stores').doc(userData.master_store.id).get().then((doc) => {
                    const data = doc.data();
                    setAdmins(data.subStoreAdminPhoneNumbers)
                    setTwoFaNeeded(data['2fa_needed'])
                })
                const storeIdsSet = new Set(storesSnapshot.docs.map(doc => doc.id));

                // Fetch all documents from the `StoreInfo` collection
                const storeInfoSnapshot = await firestore.collection('StoreInfo').get();

                // Loop through each document in the `StoreInfo` collection
                storeInfoSnapshot.forEach((storeInfoDoc) => {
                    // If the document ID from `StoreInfo` is not found in the `Stores` collection, print it
                    if (!storeIdsSet.has(storeInfoDoc.id)) {
                        console.log(`Unmatched StoreInfo Document ID: ${storeInfoDoc.id}`);
                    }
                });
            } catch (error) {
                console.error("Error finding unmatched StoreInfo documents: ", error);
            }
        };
        getMasterStoreData();
    }, []);

    const sendPostRequest = async (data, endpoint, token2) => {
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + endpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${await firebase.auth().currentUser.getIdToken(true)}`,
                    'x-master-store-email': userData.master_store.id,
                    'x-user-email': userData.email
                },
                body: JSON.stringify({ ...data, country: country })
            });
            const responseData = await response.json();
            if (response.status === '401') {
                const tokenData = await requestToken()
                await setToken('Token ' + tokenData.token)

                const resData = await sendPostRequest(data, endpoint, 'Token ' + tokenData.token)
                return resData
            }
            return responseData;
        } catch (error) {
            console.error('Error:', error.message);
            return null
        }
    };

    const requestToken = async () => {
        const loginData = {
            username: process.env.REACT_APP_TOKEN_USERNAME,
            password: process.env.REACT_APP_TOKEN_PASSWORD
        }
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + 'api/token/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(loginData)
            });

            const responseData = await response.json();
            return responseData
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleSubmit = async () => {
        setIsLoading(true);
        setInfoMessage('')
        setFormError('')
        try {
            const countryDoc = await firestore.collection('AppSetting').doc('CountrySettings').get();
            const countryData = countryDoc.data();
            setCountrySettings(countryData[country]);
            const cedulaData = {
                identification_number: cedula
            }
            const response = await sendPostRequest(cedulaData, 'registration/usercheck/', token) //status, success, data 
            if (response?.success) {
                if (response.data.is_user_registered) {
                    await getPayments();
                } else {
                    await setFormError('No encontramos ningun cliente asociado a esta cédula.');
                    setIsLoading(false);
                }
            } else if (response?.success === false) {
                setFormError(response.status);
                setIsLoading(false);
            } else {
                setFormError('Estamos teniendo problemas para conectarnos. ');
                setIsLoading(false);
            }
        } catch (error) {
            setFormError('Estamos teniendo problemas para conectarnos. ');
            setIsLoading(false);
        }
    }

    const fetchDeviceDetails = async (deviceIds, paymentDetails) => {
        try {
            const promises = deviceIds.map(async (id) => {
                const deviceDoc = await firestore.collection('Devices').doc(id).get();
                const deviceData = deviceDoc.data();
                paymentDetails[id].name = deviceData.device_commercial_name
                    ? deviceData.device_commercial_name
                    : `${deviceData.device_manufacturer} ${deviceData.device_model}`;
                paymentDetails[id].date = formatDate(deviceData.date_bought);
            });
            await Promise.all(promises);
        } catch (err) {
            setFormError("Ocurrio un error: " + err.message)
            setIsLoading(false)
        }
    };

    const getPayments = async () => {
        const paymentsCollection = firestore.collection('Payments');

        // Get a reference to the Firestore collection for users
        const usersCollection = firestore.collection('Users');

        // Query the collection based on the "user" field reference
        try {
            const queryUser = usersCollection
                .where('identification_number', '==', cedula)
                .limit(1);
            await queryUser.get().then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    setEmail(doc.id);
                    setClientName(doc.data()['first_name'] + " " + doc.data()['last_name'])
                    const showPayments2 = {};
                    let isDevicePaymentsShown = true;
                    const query = paymentsCollection
                        .where('user', '==', usersCollection.doc(doc.id))
                        .where('is_active', '==', true)
                        .orderBy('dueDate', 'asc')
                    const unsubscribe = query.onSnapshot(async (querySnapshot) => {
                        const paymentDetails = {};
                        let amountLeft = 0;

                        const deviceIds = [];
                        const showPayments2 = {};
                        let isDevicePaymentsShown = true;

                        try {
                            const promises = querySnapshot.docs.map(async (doc) => {
                                const data = doc.data();
                                amountLeft += data.actualAmount - data.amountPaid;

                                if (paymentDetails.hasOwnProperty(data.device.id)) {
                                    paymentDetails[data.device.id].payments.push({ ...data, id: doc.id });
                                } else {
                                    paymentDetails[data.device.id] = {
                                        payments: [{ ...data, id: doc.id }],
                                        name: data.device.id,
                                        date: '',
                                        isDelayed: false,
                                    };
                                    deviceIds.push(data.device.id);
                                    if (isDevicePaymentsShown) {
                                        showPayments2[data.device.id] = true;
                                        isDevicePaymentsShown = false;
                                    } else {
                                        showPayments2[data.device.id] = false;
                                    }
                                }

                                const dueDate = data.dueDate.toDate();
                                dueDate.setDate(dueDate.getDate() + 1);
                                if (!data.paid && dueDate < new Date()) {
                                    paymentDetails[data.device.id].isDelayed = true;
                                }
                            });

                            await Promise.all(promises);
                            await fetchDeviceDetails(deviceIds, paymentDetails);

                            setPayments(paymentDetails);
                            setShowPayments(showPayments2);
                            setAmountLeftToPay(amountLeft);
                            setIsLoading(false);
                            goToPage(2);
                        } catch (err) {
                            console.error('Error processing payments:', err.message);
                            setFormError('Ocurrió un problema, intente de nuevo más tarde.');
                            setIsLoading(false);
                        }
                    });

                });
            }).catch((error) => {
                setIsLoading(false);
                alert('Error: ' + error.message)
            })
        } catch (error) {
            setIsLoading(false);
            alert('Error: ' + error.message)
        }
    }

    const showPaymentScreen = (paymentId) => {
        setIsLoading(true)

        // Query the collection based on the "user" field reference
        firestore.collection('Payments').doc(paymentId).get().then(async (doc) => {
            // Access the document data
            if ((doc.data().country !== userData.country || (doc.data().self_financed && doc.data().related_store !== userData.master_store.id)) && !userData.cobranza_access) {
                alert('Este pago corresponde a una venta realizada en ' + doc.data().sub_store + ', para pagar esta cuota el cliente debe realizarlo en la tienda donde compro el equipo.');
                setIsLoading(false)
                return;
            }
            const amountToPay = doc.data()['actualAmount'] - doc.data()['amountPaid'];
            setPaymentDocId(doc.id);
            setAmountToPayDollars(amountToPay);
            setPaymentDocRef(doc);
            goToPage(3);
            let paymentReceiver = 'default';
            if (doc.data().store_financed === true) {
                paymentReceiver = doc.data().related_store
            }
            firestore.collection("StorePaymentMethods").doc(paymentReceiver)
                .onSnapshot(async (doc) => {
                    if (doc.exists) {
                        setPagoMovilData(doc.data()['pagomovil']);
                        setTransferData(doc.data()['transferBs']);
                    }
                    setIsLoading(false);
                })
            setIsLoading(false);

        });
        (async () => {
            try {
                const docRef = await firestore.collection('DollarPrice').doc('dollarPrice');
                docRef.get().then((doc) => {
                    if (doc.exists) {
                        setExchangeRate(doc.data()['bcv'])
                    } else {
                        alert('Ocurrió un problema, intente de nuevo más tarde.')
                        setIsLoading(false);
                    }
                })
                // const response = await getDollarPrices()
                // const rate = response[0].dollar
                // setExchangeRate(rate)
            } catch (error) {
                alert('Ocurrió un problema, intente de nuevo más tarde.')
                setIsLoading(false);
            }
        })();
    }

    const onHomeClick = () => {
        window.location.reload();
    }

    function convertToDotDecimal(str) {
        if (/^[+-]?(\d{1,3}(,\d{3})*|\d+)(,\d+)?$/.test(str)) {
            return str.replace(/,/g, ".");
        } else {
            return str;
        }
    }

    const isNumber = async (value) => {
        return /^[+-]?(\d{1,3}(,\d{3})*|\d+)(\.\d+)?(,\d+)?$/.test(value);
    }

    const confirmPayment = async () => {
        await updatePaymentDoc(amountReceived);
    }

    const updatePaymentDoc = async () => {
        try {
            const response = await sendStorePaymentConfirmation(paymentDocId);
            if (response?.success) {
                alert('Información de pago recibida exitosamente')
                setAmountReceived('');
                setIsLoading(false)
            } else if (response?.success == false) {
                setIsLoading(false);
                setFormError(response.status)
            } else {
                setIsLoading(false)
                setFormError("Ocurrió un problema")
            }
        } catch (err) {
            alert('Ocurrio un error')
            setIsLoading(false)
        }
    }

    const sendStorePaymentConfirmation = async (doc) => {
        const data = {
            payment_object_id: doc,
            payment_amount: parseFloat(convertToDotDecimal(amountReceived)),
            store_name: userData.master_store.id,
            sub_store: subStore,
            identification_number: cedula,
            store_user: userData.email
        }
        return await sendPostRequest(data, 'payments/processstorepayment/', token) //status, success, data 
    }

    const handlePaymentSubmit = async () => {
        if (payMethod === 'cash') {
            if (!amountReceived || !subStore) {
                setFormError('Llene todos los datos para continuar');
                return;
            } else if (await isNumber(amountReceived) === false) {
                setFormError('El monto a pagar debe ser expresado en números.');
                return;
            } else if (amountReceived > countrySettings.max_cuota_payment) {
                setFormError('El monto máximo por registro es de ' + countrySettings.currency_code + countrySettings.max_cuota_payment + ', si desea registrar más del máximo puede realizarlo en dos (o más) registros.');
                return;
            } else if (twofaNeeded && !tokenVerified) {
                setFormError('Por favor complete la verificación de 2FA para continuar');
                return;
            }
            const result = window.confirm("Deseas continuar con este registro de " + countrySettings.currency_code + amountReceived + "?")
            if (!result) {
                return;
            }
            setIsLoading(true);
            try {
                await confirmPayment();
            } catch (error) {
                setFormError('Estamos teniendo problemas para conectarnos.');
                setIsLoading(false);
            }
            return;
        }
        if (!confirmationNumber || !amountPaid || !paymentDate || !payMethod) {
            alert('Llene todos los datos para continuar');
            return;
        } else {
            setIsLoading(true);
            try {
                const response = await sendPaymentConfirmation()
                if (response?.success) {
                    setIsLoading(false)
                    alert("Su pago fue confirmado exitosamente")
                } else if (response?.success == false) {
                    setIsLoading(false)
                    alert("Error: " + response?.status)
                } else {
                    alert("Ocurrió un problema intente más tarde")
                    setIsLoading(false)
                }

            } catch (err) {
                alert(err.message)
                setIsLoading(false)
            }
        }
    };

    function formatForAPI(date) {
        // Format the date as yyyy/mm/dd
        const formattedDate = date.toLocaleString('en-CA', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            timeZone: 'America/New_York', // GMT-4 timezone (Eastern Time)
        }).replace(/\//g, '-'); // Replace slashes with dashes

        return formattedDate;
    }

    function replaceFirstCharWith58(originalString) {
        if (typeof originalString !== 'string' || originalString.length === 0) {
            // Handle invalid input or empty string
            return originalString;
        }

        const modifiedString = '58' + originalString.substring(1);
        return modifiedString;
    }

    const sendPaymentConfirmation = async () => { //todo: check if amountPaid is numerical before sending the call
        let data
        if (payMethod === "transfer") {
            data = {
                payment_object_id: paymentDocId,
                reference: confirmationNumber,
                amount: convertToDotDecimal(amountPaid),
                payment_type: "TRANSFER",
                date: formatForAPI(paymentDate),
                api_type: 'banesco'
            }
        } else {
            data = {
                payment_object_id: paymentDocId,
                reference: confirmationNumber,
                amount: convertToDotDecimal(amountPaid),
                payment_type: "P2C",
                phone_num: replaceFirstCharWith58(phoneNumber),
                bank_code: bank.split(' - ')[0],
                date: formatForAPI(paymentDate),
                api_type: 'banesco'
            }
        }
        try {
            const tokenData = await requestToken()
            const response = await fetch('https://api.krece.app/process_payment/', { //agregar un catch aqui
                method: 'POST',
                // mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': "Token " + tokenData.token
                },
                body: JSON.stringify(data)
            });
            const responseData = await response.json();
            return responseData
        } catch (error) {
            console.error('Error:', error.message);
            return null
        }
    }

    const fillToken = (token) => {
        setPhoneToken(token);
        if (token.length === 6) {
            setShowTokenField(false);
            setLoadingButton(true);
            confirmPhoneTokenFromUser(token);
        } else if (token.length > 6) {
            alert("El Token debe ser de 6 digitos.")
        }
    }

    const confirmPhoneTokenFromUser = async (ptoken) => {
        setFormError('');
        if (!ptoken) {
            setFormError('Llene todos los datos para continuar');
            return;
        }
        try {
            const codeData = {
                confirmation_code: parseInt(ptoken)
            }
            const response = await sendPostRequest(codeData, 'notifications/confirmauthcode/', token)
            if (response?.success) {
                setLoadingButton(false);
                setTokenVerified(true);
                alert('Token confirmado exitosamente');
            } else if (response?.success === false) {
                alert(response.status);
                setLoadingButton(false);
                setShowTokenField(true);
            } else {
                alert('Estamos teniendo problemas para conectarnos.');
                setLoadingButton(false);
                setShowTokenField(true);
            }
        } catch (error) {
            alert('Estamos teniendo problemas para conectarnos.');
            setLoadingButton(false);
            setShowTokenField(true);
        }
    }

    const sendPhoneToken = async () => {
        setFormError('');
        setShowTokenField(false);
        setLoadingButton(true);
        try {
            if (!selectedAdmin) {
                alert("Por favor introducir número de teléfono.")
                setIsLoading(false);
                return;
            }
            const codeData = {
                phone_number: admins[selectedAdmin],
                code_type: 'sms'
            }
            alert('Se ha enviado un token por SMS al ' + admins[selectedAdmin]);
            const response = await sendPostRequest(codeData, 'notifications/requestauthcode/', token) //status, success, data
            if (response?.success) {
                setShowTokenField(true);
                setLoadingButton(false);
            } else if (response?.success === false) {
                alert(response.status);
                setLoadingButton(false);
            } else {
                alert('Estamos teniendo problemas para conectarnos.');
                setLoadingButton(false);
            }
        } catch (error) {
            alert('Estamos teniendo problemas para conectarnos.');
            setLoadingButton(false);
        }
    }

    const formatDate = (timestamp) => {
        const date = timestamp.toDate(); // Convert Firestore timestamp to JavaScript Date object
        const month = date.toLocaleDateString('es-ES', { month: 'short' }); // Get the short month name
        const day = date.getDate(); // Get the day of the 
        return `${day} de ${month.charAt(0).toUpperCase() + month.slice(1)}`;
    };

    const GetPaymentObjects = (paymentList) => {
        var isFuturePayment = true
        var checkedByInternal = false
        var isFirst = true
        const paym = []
        paymentList.forEach((item, index) => {
            if (item.paid && item.checked_by_internal_team) {
                checkedByInternal = true
                var PayObj = <span className={classes.textSmall3}>PAGADO</span>
            } else if (item.paid && !item.checked_by_internal_team) {
                checkedByInternal = false
                var PayObj = <span className={classes.textSmall4}>Por Confirmar</span>
            } else {
                <div><span className={classes.textSmall4}></span></div>
            }
            if (isFuturePayment && !item.paid && checkedByInternal) {
                PayObj = <button onClick={() => showPaymentScreen(item.id)} className={classes.button}>
                    <span className={classes.text}>Pagar</span>
                </button>
                isFuturePayment = false
                checkedByInternal = true
            }
            // checkedByInternal = false
            if (isFirst && !item.paid) {
                PayObj = <button onClick={() => showPaymentScreen(item.id)} className={classes.button}>
                    <span className={classes.text}>Pagar</span>
                </button>
            }
            isFirst = false
            paym.push(<div key={index} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingBottom: 15, paddingTop: 15 }}>
                <span className={classes.textSmall2}>{formatDate(item.dueDate)}</span>
                {PayObj}
                <span className={classes.textSmall2}>{countrySettings.currency_code + (item.actualAmount - item.amountPaid).toFixed(2)}</span>
            </div>)
        })
        return paym
    }

    if (isLoading) {
        return (
            <Container maxWidth="sm" style={{
                marginTop: 100,
                marginHorizontal: 10,
                paddingBottom: 35,
                marginBottom: 30,
                borderRadius: '8px',
                paddingRight: 50,
                paddingLeft: 50,
                boxShadow: 'rgb(0 0 0 / 16%) 1px 1px 10px',
                paddingTop: '30px',
                backgroundColor: '#1C1C1E',
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 10, marginTop: 10 }}>
                    <BeatLoader color="#45C4D6" />
                </div>
            </Container>
        )
    }

    return (
        <div>
            <Container maxWidth="sm" style={{
                marginTop: 100,
                marginHorizontal: 10,
                paddingBottom: 35,
                marginBottom: 30,
                borderRadius: '8px',
                paddingRight: 50,
                paddingLeft: 50,
                boxShadow: 'rgb(0 0 0 / 16%) 1px 1px 10px',
                paddingTop: '30px',
                backgroundColor: '#1C1C1E',
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                {page === 1 && (
                    <div>
                        <Typography variant="h4" align="center" className={classes.title} gutterBottom>
                            Buscar cliente
                        </Typography>
                        <p className={classes.approvalMsg}>Por favor ingresa la cédula del cliente que deseas buscar</p>
                        <form>
                            <TextField
                                label="Cédula del cliente"
                                name="cedula"
                                value={cedula}
                                onChange={(e) => setCedula(e.target.value.trim())}
                                // variant="outlined"
                                className={classes.textFields}
                                margin="normal"
                                fullWidth


                                InputProps={{
                                    style: {
                                        color: 'white', // Text color
                                        borderBottom: '1px solid white'
                                    },
                                }}
                                InputLabelProps={{
                                    style: {
                                        color: '#D3D3D3', // Label color
                                    },
                                }}></TextField>
                            <Button variant="contained" className={classes.welcomeButtons} fullWidth onClick={handleSubmit} gutterBottom>
                                Buscar
                            </Button>
                            {infoMessage && <p className={classes.infoMessage}>{infoMessage}</p>}
                        </form>
                    </div>
                )}
                {page === 2 && (
                    <div style={{ alignItems: 'center', justifyContent: 'center', flex: 1, padding: 20 }}>
                        <Typography variant="h4" align="center" className={classes.title}>
                            Mis Cuotas
                        </Typography>
                        <Typography variant="h5" align="center" style={{ color: 'white', fontSize: 14, marginBottom: 20 }}>
                            {clientName}
                        </Typography>
                        {Object.keys(payments).length > 0 ? (
                            Object.keys(payments).map((item, idx) => (
                                <div key={item} className={classes.card}>
                                    <button
                                        style={{ display: 'flex', justifyContent: 'space-between', background: 'transparent', border: 'none', cursor: 'pointer', width: '100%' }}
                                        onClick={() => setShowPayments({ ...showPayments, [item]: !showPayments[item] })}
                                    >
                                        <div style={{ alignItems: 'left', justifyContent: 'left' }}>
                                            <div style={{ flex: 1, justifyContent: 'left', marginTop: 10 }}>
                                                <span style={{ color: '#ffffff', fontSize: 18 }} weight='bold'>{payments[item].name}</span>
                                            </div>
                                            <div style={{ flex: 1, justifyContent: 'left', marginBottom: 10 }}>
                                                <span style={{ fontSize: 12, color: 'silver', paddingBottom: 10, marginRight: 5 }}>{'Comprado el ' + payments[item].date}</span>
                                            </div>
                                        </div>

                                        {payments[item].isDelayed && (
                                            <div style={{ justifyContent: 'center', marginTop: 10 }}>
                                                <FaExclamationTriangle color="red" size={20} />
                                                <span style={{ color: 'red', fontSize: 10 }}>Cuota vencida</span>
                                            </div>
                                        )}

                                        <div style={{ justifyContent: 'center', alignItems: 'center', marginTop: 10 }}>
                                            {showPayments[item] ? (
                                                <FaChevronUp color="#FFFFFF80" size={20} />
                                            ) : (
                                                <FaChevronDown color="#FFFFFF80" size={20} />
                                            )}
                                        </div>
                                    </button>

                                    {showPayments[item] && (
                                        <div>
                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingBottom: 15, paddingTop: 15 }}>
                                                <div ><span className={classes.textSmall5} weight='bold'>Vence</span></div>
                                                <div ><span className={classes.textSmall5} weight='bold'>Estado</span></div>
                                                <div ><span className={classes.textSmall5} weight='bold'>Debe</span></div>
                                            </div>
                                            {/* Assuming GetPaymentObjects is a function that renders sub-elements */}
                                            {GetPaymentObjects(payments[item].payments)}
                                            <div style={{ display: 'flex', justifyContent: 'space-between', paddingBottom: 15, paddingTop: 15 }}>
                                                <div>
                                                    <span className={classes.textSmall} weight='bold'>Saldo restante</span>
                                                </div>
                                                <div>
                                                    <span className={classes.textSmall} weight='bold'>{countrySettings.currency_code + formatNumber(amountLeftToPay.toFixed(2))}</span>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            ))
                        ) : (
                            <div className={classes.card}>
                                <span className={classes.textSmall2}>{`No tienes cuotas pendientes. Dirígete a una de nuestras tiendas aliadas para obtener tu equipo a cuotas HOY!`}</span>
                            </div>
                        )}
                        <Button
                            variant="contained"
                            onClick={backToWelcomePage}
                            className={classes.welcomeButtons}
                        >
                            Salir
                        </Button>
                    </div>
                )}
                {page === 3 && (
                    <div style={{ alignItems: 'center', justifyContent: 'center', flex: 1 }}>
                        <Typography variant="h4" align="center" className={classes.title}>
                            Pago de cuota
                        </Typography>
                        <div style={{ padding: 20 }}>
                            <Typography className={classes.label}>Monto a pagar</Typography>
                            <Typography className={classes.label3}>{"$" + (amountToPayDollars).toFixed(2) + " / " + " Bs. " + (amountToPayDollars * exchangeRate).toFixed(2) + " (A tasa BCV)"}</Typography>
                            <TextField
                                select
                                label="Método de pago"
                                name="payMethod"
                                value={payMethod}
                                onChange={(e) => setPayMethod(e.target.value)}
                                className={classes.textFields}
                                margin="normal"
                                fullWidth


                                InputProps={{
                                    style: {
                                        color: 'white', // Text color
                                        borderBottom: '1px solid white'
                                    },
                                }}
                                InputLabelProps={{
                                    style: {
                                        color: '#D3D3D3', // Label color
                                    },
                                }}
                            >
                                {!userData.cobranza_access && <MenuItem value="cash">Pago en tienda</MenuItem>}
                                {country === 'venezuela' && [<MenuItem value="pagomovil">Pago Movil</MenuItem>,
                                <MenuItem value="transfer">Transferencia</MenuItem>]}
                            </TextField>
                            {payMethod === 'cash' && <>
                                <TextField
                                    label={"Monto recibido (en " + countrySettings.currency_name + ")"}
                                    name="amountReceived"
                                    value={amountReceived}
                                    onChange={(e) => setAmountReceived(e.target.value)}
                                    id="outlined-number"
                                    margin="normal"
                                    className={classes.textFields}
                                    fullWidth

                                    InputProps={{
                                        style: {
                                            color: 'white', // Text color
                                            borderBottom: '1px solid white'
                                        },
                                    }}
                                    InputLabelProps={{
                                        style: {
                                            color: '#D3D3D3', // Label color
                                        },
                                    }}
                                />
                                <TextField
                                    select
                                    label="Local"
                                    name="subStore"
                                    value={subStore}
                                    onChange={(e) => setSubStore(e.target.value)}
                                    className={classes.textFields}
                                    margin="normal"

                                    fullWidth
                                    InputProps={{
                                        style: {
                                            color: 'white', // Text color
                                            borderBottom: '1px solid white'
                                        },
                                    }}
                                    InputLabelProps={{
                                        style: {
                                            color: '#D3D3D3', // Label color
                                        },
                                    }}
                                >
                                    {subStoreList.map((item, index) => (
                                        <MenuItem value={item}>{item}</MenuItem>
                                    ))}
                                </TextField>
                                {twofaNeeded && <><Typography variant="h6" className={classes.title2} style={{ marginTop: 25 }} gutterBottom>
                                    Verificación 2FA
                                </Typography>
                                    <div className={classes.border2}>
                                        <TextField
                                            select
                                            label="Admin"
                                            name="selectedAdmin"
                                            value={selectedAdmin}
                                            onChange={(e) => setSelectedAdmin(e.target.value)}
                                            className={classes.textFields}
                                            margin="normal"
                                            fullWidth
                                            InputProps={{
                                                style: {
                                                    color: 'white', // Text color
                                                    borderBottom: '1px solid white'
                                                },
                                            }}
                                            InputLabelProps={{
                                                style: {
                                                    color: '#D3D3D3', // Label color
                                                },
                                            }}
                                        >
                                            {Object.keys(admins).map((item, index) => (
                                                <MenuItem value={item}>{item + " - ******" + admins[item].substring(admins[item].length - 4)}</MenuItem>
                                            ))}
                                        </TextField>
                                        {tokenVerified ? <CheckCircle style={{ color: "#45C4D6", alignSelf: 'center' }} /> : <Button
                                            variant="contained"
                                            onClick={sendPhoneToken}
                                            className={classes.welcomeButtons2}
                                            disabled={tokenVerified}
                                        >
                                            Enviar Token
                                        </Button>}
                                        {showTokenField && <TextField
                                            label="Token"
                                            name="phoneToken"
                                            type="tel"
                                            value={phoneToken}
                                            onChange={(e) => fillToken(e.target.value)}
                                            className={classes.textFields}
                                            margin="normal"
                                            InputProps={{
                                                style: {
                                                    color: 'white', // Text color
                                                    borderBottom: '1px solid white'
                                                },
                                            }}
                                            InputLabelProps={{
                                                style: {
                                                    color: '#D3D3D3', // Label color
                                                },
                                            }}
                                        />}
                                        {loadingButton &&
                                            <div style={{ marginTop: 15 }}>
                                                <CircularProgress color="secondary" />
                                            </div>
                                        }
                                    </div>
                                </>}
                            </>}
                            {payMethod !== 'cash' && <>
                                <div>
                                    <TextField
                                        label="Número de referencia"
                                        type="confirmationNumber"
                                        name="confirmationNumber"
                                        value={confirmationNumber}
                                        onChange={(e) => setConfirmationNumber(e.target.value.trim())}
                                        // variant="outlined"
                                        className={classes.textFields}
                                        margin="normal"
                                        fullWidth


                                        InputProps={{
                                            style: {
                                                color: 'white', // Text color
                                                borderBottom: '1px solid white'
                                            },
                                        }}
                                        InputLabelProps={{
                                            style: {
                                                color: '#D3D3D3', // Label color
                                            },
                                        }}></TextField>
                                </div>
                                <div>
                                    <TextField
                                        label="Monto del pago (en Bolivares)"
                                        type="amountPaid"
                                        name="amountPaid"
                                        value={amountPaid}
                                        onChange={(e) => setAmountPaid(e.target.value.trim())}
                                        // variant="outlined"
                                        className={classes.textFields}
                                        margin="normal"
                                        fullWidth


                                        InputProps={{
                                            style: {
                                                color: 'white', // Text color
                                                borderBottom: '1px solid white'
                                            },
                                        }}
                                        InputLabelProps={{
                                            style: {
                                                color: '#D3D3D3', // Label color
                                            },
                                        }}></TextField>
                                </div>
                                <TextField
                                    label="Fecha del pago"
                                    name="paymentDate"
                                    type="date"
                                    value={paymentDate}
                                    onChange={(e) => setPaymentDate(e.target.value)}
                                    // variant="outlined"
                                    className={classes.textFields}
                                    margin="normal"
                                    fullWidth

                                    InputProps={{
                                        style: {
                                            color: 'white', // Text color
                                            borderBottom: '1px solid white'
                                        },
                                    }}
                                    InputLabelProps={{
                                        style: {
                                            color: '#D3D3D3', // Label color
                                        },
                                    }}></TextField></>}
                            {payMethod === 'pagomovil' && <div>
                                <TextField
                                    label="Teléfono de origen (Formato Ejemplo: 04141234567)"
                                    type="phoneNumber"
                                    name="phoneNumber"
                                    value={phoneNumber}
                                    onChange={(e) => setPhoneNumber(e.target.value.trim())}
                                    // variant="outlined"
                                    className={classes.textFields}
                                    margin="normal"
                                    fullWidth

                                    InputProps={{
                                        style: {
                                            color: 'white', // Text color
                                            borderBottom: '1px solid white'
                                        },
                                    }}
                                    InputLabelProps={{
                                        style: {
                                            color: '#D3D3D3', // Label color
                                        },
                                    }}></TextField>

                                <TextField
                                    select
                                    label="Banco de origen del Pagomovil"
                                    name="bank"
                                    value={bank}
                                    onChange={(e) => setBank(e.target.value)}
                                    className={classes.textFields}
                                    margin="normal"
                                    fullWidth

                                    InputProps={{
                                        style: {
                                            color: 'white', // Text color
                                            borderBottom: '1px solid white'
                                        },
                                    }}
                                    InputLabelProps={{
                                        style: {
                                            color: '#D3D3D3', // Label color
                                        },
                                    }}
                                >
                                    {bankCodesList.map((item, index) => (
                                        <MenuItem value={item}>{item}</MenuItem>
                                    ))}
                                </TextField>
                            </div>}
                            {formError && <p style={{ color: "#e84e4f" }}>{formError}</p>}
                            <Button variant="contained" className={classes.welcomeButtons} fullWidth onClick={handlePaymentSubmit} gutterBottom>
                                Enviar
                            </Button>
                            <Button
                                variant="contained"
                                className={classes.welcomeButtons}
                                startIcon={<ArrowBack />}
                                onClick={() => goToPage(2)}
                                style={{ marginTop: '10px' }}
                            >
                                Atrás
                            </Button>
                            {userData.country === 'venezuela' && <><Typography variant="h5" className={classes.title}>
                                Detalles de las cuentas a pagar
                            </Typography>
                                <Typography className={classes.label}>
                                    PagoMovil <br />
                                    {'Banco: ' + pagoMovilData.bank}<br />
                                    {'Rif: ' + pagoMovilData.rif}<br />
                                    {'#Teléfono: ' + pagoMovilData.phoneNumber}<br />
                                    <br />
                                    Transferencia<br />
                                    {'Cuenta: ' + transferData.account}<br />
                                    {'Rif: ' + transferData.rif}<br />
                                    {'Banco: ' + transferData.bank}<br />
                                    {'Tipo: ' + transferData.type}<br />
                                    <br />
                                </Typography></>}
                        </div>
                    </div>
                )}
                {page === 4 && (
                    <div>
                        <Typography variant="h4" align="center" className={classes.title}>
                            Gracias por completar su pago
                        </Typography>
                        <Typography className={classes.label}>Hemos recibido la información de pago. Nuestro equipo confirmará su pago en las próximas 24 horas y lo podrá ver reflejado en la app</Typography>
                        <Button
                            variant="contained"
                            className={classes.welcomeButtons}
                            startIcon={<ArrowBack />}
                            onClick={() => goToPage(2)}
                            style={{ marginTop: '10px' }}
                        >
                            Volver
                        </Button>
                    </div>
                )}
            </Container>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    card: {
        backgroundColor: '#2A2E32',
        borderRadius: 25,
        borderBottomWidth: 0,
        marginLeft: 5,
        marginRight: 5,
        marginBottom: 10,
        paddingLeft: 20,
        paddingRight: 20,
        paddingVertical: 20,
        justifyContent: 'center',
        elevation: 5,
        minWidth: 300
    },
    paymentItem2: {
        flex: 1,
        display: 'row',
        marginBottom: 15,
        justifyContent: 'center',
        alignItems: 'center'
    },
    approvalMsg: {
        marginBottom: theme.spacing(2), // Adjust the padding as needed
        textAlign: 'center',
        color: 'white'
    },
    welcomeButtons: {
        backgroundColor: '#45C4D6',
        color: '#000000',
        marginTop: 10,
        marginBottom: 10
    },
    border2: {
        borderRadius: '8px',
        border: "1px solid #ccc",
        paddingRight: "15px",
        paddingLeft: "15px",
        paddingBottom: 5,
        marginRight: 20,
        marginBottom: 20,
        display: 'flex',
        justifyContent: 'space-between'
    },
    welcomeButtonsNoBorder: {
        backgroundColor: '#000000',
        color: '#45C4D6',
        marginTop: 10,
        marginBottom: 10
    },
    textFields: {
        backgroundColor: '#1C1C1E',
        color: 'white',
        borderColor: "white",
        borderBottomColor: "white",
        borderRadius: 5,
        borderBottomLeftRadius: 5,
        borderBottomRightRadius: 5,
        paddingLeft: 5
    },
    containerWelcome: {
        display: 'flex',
        flexDirection: 'column',
        height: 700,
    },
    infoMessage: {
        color: "white"
    },
    title: {
        color: '#ffffff',
        marginBottom: 20,
        fontSize: 26,
        fontWeight: 'bold'
    },
    textSmall: {
        fontSize: 16,
        color: 'white',
        fontWeight: 'bold',
    },
    textSmall2: {
        fontSize: 14,
        color: 'white',
    },
    textSmall3: {
        fontSize: 14,
        color: '#45C4D6',
        fontWeight: 'bold'
    },
    textSmall4: {
        fontSize: 14,
        color: 'white',
        fontWeight: 'bold'
    },
    textSmall5: {
        fontSize: 16,
        color: 'white',
        fontWeight: 'bold',
    },
    text: {
        color: 'white',
        fontWeight: 'bold',
        textAlign: 'center',
        fontSize: 14
    },
    paymentItem: {
        // width: '100%',
        paddingTop: 5,
        paddingBottom: 10,
        diplay: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 10,
    },
    button: {
        width: 100,
        height: 40,
        borderRadius: 50,
        backgroundColor: '#45C4D6'
    },
    label: {
        fontSize: 20,
        marginBottom: 8,
        color: 'white'
    },
    label2: {
        fontSize: 20,
        marginBottom: 8,
        marginTop: 20,
        color: 'white'
    },
    label3: {
        fontSize: 16,
        marginBottom: 8,
        color: '#45C4D6',
        marginLeft: 20
    },
    welcomeButtons2: {
        backgroundColor: '#45C4D6',
        color: '#2A2E32',
        marginTop: 15,
        marginLeft: 15,
        height: 45,
        width: 200
    },
}));

export default Pay;
