import React, { useEffect, useState, useContext } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { makeStyles, Button, TextField, Typography, MenuItem } from '@material-ui/core';
import firestore from '../firestore'
import firebase from 'firebase/compat/app';
import MenuButton from '../components/MenuButton';
import { checkboxClasses } from '@mui/material';
import { GlobalContext } from '../context/GlobalContext';
import { FaChevronDown, FaChevronRight } from 'react-icons/fa';
import { saveAs } from 'file-saver';
import Papa from 'papaparse';

const useStyles = makeStyles({
    root: {
        width: '1500px',
        height: '1200px',
        color: 'white',
        flexGrow: 1,
        margin: '0 auto',
    },
    title: {
        color: '#ffffff',
        fontSize: 20,
        marginLeft: 40
    },
    title2: {
        color: 'white',
        marginBottom: 20,
        fontSize: 26,
        fontWeight: 'bold',
        marginLeft: 20
    },
    cell: {
        color: 'silver',
        backgroundColor: '#1C1C1E',
    },
    font: { color: 'white' },
    welcomeButtons: {
        backgroundColor: '#45C4D6',
        color: '#2A2E32',
        marginTop: 30,
        marginBottom: 20
    },
    textSmall5: {
        fontSize: 16,
        color: '#2A2E32',
        fontWeight: 'bold',
    },
    textFields: {
        backgroundColor: '#2A2E32',
        color: 'white',
        borderColor: "white",
        borderBottomColor: "white",
        borderRadius: 5,
        borderBottomLeftRadius: 5,
        borderBottomRightRadius: 5,
        paddingLeft: 5,
        width: 200,
        marginLeft: 40
    },
    textFields2: {
        backgroundColor: 'white',
        color: '#2A2E32',
        borderColor: "white",
        borderBottomColor: "white",
        borderRadius: 5,
        borderBottomLeftRadius: 5,
        borderBottomRightRadius: 5,
        paddingRight: 500,
        marginLeft: 40
    },
});


const AdminTable = () => {
    const [users, setUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [country, setCountry] = useState('')
    const [storeCommercialName, setStoreCommercialName] = useState('')
    const [devices, setDevices] = useState([]);
    const [payments, setPayments] = useState([]);
    const [transactions, setTransactions] = useState([]);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [cedula, setCedula] = useState('');
    const [cedula2, setCedula2] = useState('');
    const [cedula3, setCedula3] = useState('');
    const [cedula4, setCedula4] = useState('');
    const [cedula5, setCedula5] = useState('');
    const [cedula6, setCedula6] = useState('');
    const [cedula7, setCedula7] = useState('');
    const [showUsers, setShowUsers] = useState(true);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [oldCedula, setOldCedula] = useState('');
    const [deviceName, setDeviceName] = useState('');
    const [newCedula, setNewCedula] = useState('');
    const [devId2, setDevId2] = useState('');
    const [devId, setDevId] = useState('');
    const [newPrice, setNewPrice] = useState('');
    const [newDeviceName, setNewDeviceName] = useState('');
    const [email, setEmail] = useState('');
    const [email2, setEmail2] = useState('');
    const [deviceID, setDeviceID] = useState('');
    const [deviceID2, setDeviceID2] = useState('');
    const [deviceID3, setDeviceID3] = useState('');
    const [showUserData, setShowUserData] = useState(false);
    const [searchKeyType, setSearchKeyType] = useState('');
    const [searchKey, setSearchKey] = useState('');
    const [showStorePayments, setShowStorePayments] = useState(false);
    const [showPaymentsToCheck, setShowPaymentsToCheck] = useState(false);
    const [showKpis, setShowKpis] = useState(false);
    const [showDbObjects, setShowDbObjects] = useState(true);
    const [nonSelfFinancedStoresPayments, setNonSelfFinancedStoresPayments] = useState([]);
    const [selfFinancedStoresPayments, setSelfFinancedStoresPayments] = useState([]);
    const [storeToChange, setStoreToChange] = useState('');
    const [limitIncrease, setLimitInrease] = useState('');
    const [transactionId, setTransactionId] = useState('');
    const [paymentDate, setPaymentDate] = useState('');
    const [referenceNumber, setReferenceNumber] = useState('');
    const [reportedAmount, setReportedAmount] = useState('');
    const [cobranzaKpi, setCobranzaKpi] = useState('');
    const [showCobranzaKpi, setShowCobranzaKpi] = useState('');
    // create new store
    const [masterUser, setMasterUser] = useState('');
    const [masterStore, setMasterStore] = useState('');
    const [kreceFee, setKreceFee] = useState('');
    const [kreceFixedFee, setKreceFixedFee] = useState('');
    const [storeName, setStoreName] = useState('');
    const [subStores, setSubStores] = useState('');
    const [principalStore, setPrincipalStore] = useState('');
    const [instagram, setInstagram] = useState('');
    const [instagramLink, setInstagramLink] = useState('');
    const [whatsappLink, setWhatsappLink] = useState('');
    const [logoUrl, setLogoUrl] = useState('');
    const [citySubStoreList, setCitySubStoreList] = useState([]);
    const [hoursSubStoreList, setHoursSubStoreList] = useState([]);
    const [locationSubStoreList, setLocationSubStoreList] = useState([]);
    const [nameSubStoreList, setNameSubStoreList] = useState([]);
    const [newCreditLine, setNewCreditLine] = useState('');
    const [salesSum, setSalesSum] = useState(0);
    const [screenShown, setScreenShown] = useState('');
    const [masterEmail, setMasterEmail] = useState('');
    const [clientType, setClientType] = useState('');

    const [file, setFile] = useState(null)

    const { userData } = useContext(GlobalContext);


    const classes = useStyles();


    const isTimestampInPast = (firestoreTimestamp) => {
        const currentTimestamp = Date.now();
        const firestoreTimeMillis = firestoreTimestamp.toMillis();
        return firestoreTimeMillis < currentTimestamp;
    };

    const formatDate = (date) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return date.toDate().toLocaleDateString('es-ES', options);
    }

    useEffect(() => {

    }, []);

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            setFile(selectedFile);
        }
    };

    const parseCSV = (csvString) => {
        const results = {};
        Papa.parse(csvString, {
            header: true,
            complete: (data) => {
                data.data.forEach((row) => {
                    results[row['Nombre de Aliado']] = row['ID'];
                });
            },
        });
        return results;
    };

    const exportTiendasCSV = async () => {
        if (!file) {
            alert("Please upload a CSV file first.");
            return;
        }

        try {
            const csvString = await file.text();
            const aliados = parseCSV(csvString);

            const storeInfoCollection = firestore.collection('StoreInfo');

            // Fetch all documents from the StoreInfo collection
            const querySnapshot = await storeInfoCollection.get();

            // Array to store CSV data
            const csvData = [];

            querySnapshot.forEach((doc) => {
                const data = doc.data();
                const storeName = data.store_name || '';

                if (Array.isArray(data.sub_stores)) {
                    data.sub_stores.forEach((subStore) => {
                        if (!aliados[storeName]) {
                            console.log('Store: ' + storeName)
                        }
                        const storeRow = {
                            'Nombre de la tienda': subStore.store_name || '',
                            'Link de google maps': `https://www.google.com/maps/search/?api=1&query=${subStore.location || ''}`,
                            'Horario': subStore.hours_of_operation || '',
                            'Aliado': aliados[storeName] || '',
                            'Direccion corta': subStore.location || '',
                            'Ciudad': JSON.stringify(Array.from([subStore.city])) || '',
                            'Ubicacion maps': JSON.stringify({ formatted: subStore.location, city: subStore.city })// `${subStore.location} ${subStore.city || ''}`, // Assuming Wix format includes city as well
                        };

                        // Push the row into csvData array
                        if (data.country === userData.country) {
                            csvData.push(storeRow);
                        }

                    });
                }
            });

            // Use PapaParse to convert JSON array to CSV string
            const csvStringOutput = Papa.unparse(csvData);

            // Create a Blob object for the CSV file
            const blob = new Blob([csvStringOutput], { type: 'text/csv;charset=utf-8;' });

            // Create a link to download the CSV file
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.setAttribute('download', 'Tiendas.csv');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            console.log('Tiendas.csv file created successfully!');
        } catch (error) {
            console.error('Error exporting Tiendas to CSV:', error);
        }
    };

    async function exportAliadosCSV() {
        try {
            const storeInfoCollection = firestore.collection('StoreInfo');

            // Fetch all documents from the StoreInfo collection
            const querySnapshot = await storeInfoCollection.get();

            // Array to store CSV data
            const csvData = [];

            // Iterate through each document and extract required fields
            querySnapshot.forEach((doc) => {
                const data = doc.data();

                // Extracting the required fields from the document
                const storeName = data.store_name || '';
                const logoUrl = data.logo_url || '';
                const whatsappLink = data.contact?.whatsapp_link || '';
                const instagramLink = data.contact?.instagram_link || '';

                // Extract and format cities from the `sub_stores` array using a Set to ensure uniqueness
                const uniqueCities = new Set();
                if (Array.isArray(data.sub_stores)) {
                    data.sub_stores.forEach((store) => {
                        if (store.city) {
                            uniqueCities.add(store.city); // Only add unique cities
                        }
                    });
                }

                // Convert the Set to an array and format as ["City1", "City2", "City3"]
                const formattedCities = JSON.stringify(Array.from(uniqueCities));

                // Create a row for each document with the desired fields
                const row = {
                    'Nombre de Aliado': storeName,
                    'Logo/Marca': logoUrl,
                    'Whatsapp Link': whatsappLink,
                    'Instagram': instagramLink,
                    'Ciudades': formattedCities,
                };

                // Push the row into csvData array
                if (data.country === userData.country) {
                    csvData.push(row);
                }

            });

            // Use PapaParse to convert JSON array to CSV string
            const csvString = Papa.unparse(csvData);

            // Create a Blob object for the CSV file
            const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });

            // Use FileSaver to save the CSV file with the name Aliados.csv
            saveAs(blob, 'Aliados.csv');

            console.log('CSV file created successfully!');
        } catch (error) {
            console.error('Error exporting Aliados to CSV:', error);
        }
    }

    async function exportCitiesCSV() {
        try {
            const storeInfoCollection = firestore.collection('StoreInfo');

            // Fetch all documents from the StoreInfo collection
            const querySnapshot = await storeInfoCollection.get();

            // Set to store unique cities
            const uniqueCities = new Set();

            // Iterate through each document
            querySnapshot.forEach((doc) => {
                const data = doc.data();

                // Check if the document has the 'sub_stores' field and it is an array
                if (Array.isArray(data.sub_stores)) {
                    // Loop through each item in sub_stores array
                    data.sub_stores.forEach((store) => {
                        if (store.city && data.country === userData.country) {
                            // Add the city to the Set to ensure uniqueness
                            uniqueCities.add(store.city);
                        }
                    });
                }
            });

            // Convert the Set to an array for easier manipulation
            const citiesArray = Array.from(uniqueCities);

            // Create a CSV formatted object
            const csvData = citiesArray.map((city) => ({ Title: city }));

            // Use PapaParse to convert JSON array to CSV string
            const csvString = Papa.unparse(csvData);

            // Create a Blob object for the CSV file
            const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });

            // Use FileSaver to save the CSV file with the name Ciudades.csv
            saveAs(blob, 'Ciudades.csv');

            console.log('CSV file created successfully!');
        } catch (error) {
            console.error('Error exporting cities to CSV:', error);
        }
    }

    const sendGetRequest = async (endpoint, token2) => {
        try {

            const response = await fetch(process.env.REACT_APP_API_URL + endpoint, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${await firebase.auth().currentUser.getIdToken(true)}`,
                    'x-master-store-email': 'admin@krece.app',
                    'x-user-email': null
                },
            });
            const responseData = await response.json();
            if (response.status === '401') {
                const tokenData = await requestToken()

                const resData = await sendGetRequest(endpoint, 'Token ' + tokenData.token)
                return resData
            }
            return responseData;
        } catch (error) {
            console.error('Error:', error.message);
            return null
        }
    };

    const sendPostRequest = async (data, endpoint, token2) => {
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + endpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token2
                    // 'Authorization': `Bearer ${await firebase.auth().currentUser.getIdToken(true)}`,
                    // 'x-master-store-email': 'admin@krece.app',
                    // 'x-user-email': null
                },
                body: JSON.stringify({ ...data, country: userData.country, store_user: userData.email })
            });
            const responseData = await response.json();

            if (response.status === '401') {
                const tokenData = await requestToken()

                const resData = await sendPostRequest(data, endpoint, 'Token ' + tokenData.token)
                return resData
            }
            return responseData;
        } catch (error) {
            console.error('Error:', error.message);
            return null
        }
    };

    const requestToken = async () => {
        const loginData = {
            username: process.env.REACT_APP_TOKEN_USERNAME,
            password: process.env.REACT_APP_TOKEN_PASSWORD
        }
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + '/api/token/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(loginData)
            });

            const responseData = await response.json();
            return responseData
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const clearUserDevice = async () => {
        setIsLoading(true);
        if (!cedula2) {
            alert('Por favor rellene la casilla de cedula.');
            setIsLoading(false);
        }
        try {
            const usersCollection = firestore.collection('Users');
            const paymentsCollection = firestore.collection('Payments');
            const devicesCollection = firestore.collection('Devices');
            const remindersCollection = firestore.collection('Reminders');

            const queryUser = usersCollection
                .where('identification_number', '==', cedula2)
                .limit(1);
            await queryUser.get().then((querySnapshot) => {
                querySnapshot.forEach(async (doc) => {
                    if (doc.data()['registration_status'] === "device_enrolled") {
                        //change "user" field in Devices for this user
                        var deviceId = "";
                        const deviceQuery = devicesCollection
                            .where('user', '==', usersCollection.doc(doc.id))
                            .where('is_released', '==', false)

                        await deviceQuery.get().then((querySnapshot) => {
                            querySnapshot.forEach((doc) => {
                                setDeviceID(doc.data()['nuovopay_device_id']);
                                deviceId = doc.data()['nuovopay_device_id'];
                                doc.ref.delete();
                            })
                        })
                        //change payment "user" field to the new user
                        const paymentQuery = paymentsCollection
                            .where('user', '==', usersCollection.doc(doc.id))
                            .where('is_active', '==', true)
                        await paymentQuery.get().then((querySnapshot) => {
                            querySnapshot.forEach((doc) => {
                                doc.ref.delete();
                            })
                        })
                        //change the Reminders "user" field to the new user
                        const reminderQuery = remindersCollection
                            .where('user', '==', usersCollection.doc(doc.id))
                        await reminderQuery.get().then((querySnapshot) => {
                            querySnapshot.forEach((doc) => {
                                doc.ref.delete();
                            })
                        })
                        doc.ref.update({
                            registration_status: 'application_reviewed'
                        })
                        alert('Se elimino exitosamente el celular. Ahora se debe eliminar manualmente de Nuovo usando el ID: ' + deviceId);
                        setIsLoading(false);
                    } else {
                        alert('Este cliente no tiene celular registrado');
                        setIsLoading(false);
                    }
                })
            }).catch((error) => {
                alert('Esta cedula no existe en nuestra base de datos');
                setIsLoading(false);
            });
        } catch (error) {
            alert('Estamos teniendo problemas para conectarnos.');
            setIsLoading(false);
        }
    }

    const changeUserEmail = async () => {
        setIsLoading(true);
        try {
            const usersCollection = firestore.collection('Users');
            const paymentsCollection = firestore.collection('Payments');
            const devicesCollection = firestore.collection('Devices');
            const remindersCollection = firestore.collection('Reminders');
            const transactionsCollection = firestore.collection('Transactions');

            const queryUser = usersCollection
                .where('identification_number', '==', cedula.trim())
                .limit(1);
            await queryUser.get().then((querySnapshot) => {
                querySnapshot.forEach(async (doc) => {
                    const dataToClone = doc.data();

                    if (!dataToClone) {
                        alert("Source document not found or has no data.");
                        setIsLoading(false)
                        return;
                    }
                    if (doc.id === email) {
                        alert("El correo introducido es igual al anterior, por favor introduzca un correo distinto.");
                        setIsLoading(false)
                        return;
                    }

                    // Step 4: Write data to the new document in the destination collection
                    const destinationDocumentRef = firestore.collection('Users').doc(email.toLowerCase().trim());
                    await destinationDocumentRef.set(dataToClone);
                    if (dataToClone['registration_status'] === "device_enrolled") {
                        //change "user" field in Devices for this user
                        const deviceQuery = devicesCollection
                            .where('user', '==', usersCollection.doc(doc.id))

                        await deviceQuery.get().then((querySnapshot) => {
                            querySnapshot.forEach((doc) => {
                                doc.ref.update({
                                    user: usersCollection.doc(email.toLowerCase().trim())
                                })
                            })
                        })
                        //change payment "user" field to the new user
                        const paymentQuery = paymentsCollection
                            .where('user', '==', usersCollection.doc(doc.id))
                        await paymentQuery.get().then((querySnapshot) => {
                            querySnapshot.forEach((doc) => {
                                doc.ref.update({
                                    user: usersCollection.doc(email.toLowerCase().trim())
                                })
                            })
                        })
                        //change the Reminders "user" field to the new user
                        const reminderQuery = remindersCollection
                            .where('user', '==', usersCollection.doc(doc.id))
                        await reminderQuery.get().then((querySnapshot) => {
                            querySnapshot.forEach((doc) => {
                                doc.ref.update({
                                    user: usersCollection.doc(email.toLowerCase().trim())
                                })
                            })
                        })
                        const transactionQuery = transactionsCollection
                            .where('user', '==', usersCollection.doc(doc.id))
                        await transactionQuery.get().then((querySnapshot) => {
                            querySnapshot.forEach((doc) => {
                                doc.ref.update({
                                    user: usersCollection.doc(email.toLowerCase().trim())
                                })
                            })
                        })
                        doc.ref.delete();
                        alert('El correo se cambió correctamente!')
                        setIsLoading(false);
                    } else {
                        doc.ref.delete();
                        alert('El correo se cambió correctamente!')
                        setIsLoading(false);
                    }
                })
            }).catch((error) => {
                alert('Ocurrió un problema, intente de nuevo más tarde.')
                setIsLoading(false);
            });
        } catch (error) {
            alert('Estamos teniendo problemas para conectarnos.');
            setIsLoading(false);
        }
    }

    //move this to cobranza
    const confirmLatePayment = (id) => {
        const docRef = firestore.collection("Payments").doc(id);
        docRef.update({
            checked_by_internal_team: true
        }).then(() => {
            alert('Pago tardio confirmado');
        })
            .catch((error) => {
                alert('Ocurrio un error, no pudimos confirmar:' + error);
            });
    }

    const getSalesKpi = async () => {
        setIsLoading(true);
        try {
            await firestore.collection('History').doc('DailyDeviceLimits').get().then((doc) => {
                let sum = 0
                const data = doc.data()
                for (const [key, value] of Object.entries(data)) {
                    if (!key.includes('BitMercado')) {
                        sum = sum + value.devices_sold
                    }

                }
                setSalesSum(sum)
            })
            setIsLoading(false);
        } catch (err) {
            console.log(err.message)
            alert('Ocurrio un problema')
            setIsLoading(false);
        }
    }

    const getCobranzaKpi = async () => {
        setIsLoading(true);
        try {
            const data = {
                cobranza_owner: '18761844@krece.app'
            }
            const response = await sendPostRequest(data, 'get_cobranza_stats/', process.env.REACT_APP_API_TOKEN);
            if (response?.success) {
                setCobranzaKpi(JSON.stringify(response.data))
                setShowCobranzaKpi(true)
                setIsLoading(false);
            } else if (!response?.success) {
                alert("Error: " + response.status)
                setIsLoading(false);
            } else {
                alert("Ocurrio un problema intente mas tarde.")
                setIsLoading(false);
            }
        } catch (err) {
            alert("Error: " + err.message)
            setIsLoading(false);
        }

    }

    const isEmptyObject = (obj) => Object.keys(obj).length === 0;

    const searchClientData = async () => {
        setIsLoading(true)
        setUsers([])
        setDevices([])
        setTransactions([])
        setPayments([])
        if (!searchKeyType || !searchKey) {
            alert("Por favor rellene todos los datos");
            setIsLoading(false);
            return;
        }
        try {
            const data = {
                KeyType: searchKeyType,
                KeyValue: searchKey.toLowerCase().trim()
            }
            const response = await sendPostRequest(data, 'get_user_data/', process.env.REACT_APP_API_TOKEN);
            if (response?.success) {
                const responseData = JSON.parse(response.data.replaceAll('NaN', '""'));
                if (responseData.devices.length > 0) {
                    setDevices(responseData.devices)
                }
                if (responseData.payments.length > 0) {
                    setPayments(responseData.payments)
                }
                if (responseData.transactions.length > 0) {
                    setTransactions(responseData.transactions)
                }
                if (Object.keys(responseData.user).length === 0) {
                    setShowUsers(false)
                } else {
                    setShowUsers(true)
                }
                setUsers([responseData.user])
                setShowUserData(true)
                setIsLoading(false);
            } else if (!response?.success) {
                alert("Error: " + response.status)
                setIsLoading(false);
            } else {
                alert("Ocurrio un problema intente mas tarde.")
            }
        } catch (err) {
            alert(err.message)
        }

    }

    const deleteTransaction = async () => {
        setIsLoading(true)
        if (!transactionId) {
            alert("Por favor rellene todos los datos");
            setIsLoading(false);
            return;
        }
        try {
            const data = {
                transaction_id: transactionId.trim()
            }
            const response = await sendPostRequest(data, 'delete_payment/', process.env.REACT_APP_API_TOKEN);

            if (response?.success) {
                alert("Transaccion eliminada exitosamente")
                setIsLoading(false);
            } else if (!response?.success) {
                alert("Error: " + response.status)
                setIsLoading(false);
            } else {
                alert("Ocurrio un problema intente mas tarde.")
            }
        } catch (err) {
            alert(err.message)
        }

    }

    const changePaymentDate15and30 = async () => {
        setIsLoading(true)
        if (!cedula3) {
            alert("Por favor rellene todos los datos");
            setIsLoading(false);
            return;
        }
        try {
            const data = {
                identification_number: cedula3.trim()
            }
            const response = await sendPostRequest(data, 'update_payments_to_15_30/', process.env.REACT_APP_API_TOKEN);

            if (response?.success) {
                alert("Fechas de pago cambiadas exitosamente")
            } else if (!response?.success) {
                alert("Error: " + response.status)
            } else {
                alert("Ocurrio un problema intente mas tarde.")
            }
            setIsLoading(false);
        } catch (err) {
            alert(err.message)
        }
    }

    const changeDevicePrice = async () => {
        setIsLoading(true)
        if (!devId || !newPrice) {
            alert("Por favor rellene todos los datos");
            setIsLoading(false);
            return;
        }
        try {
            const data = {
                device_id: devId.trim(),
                device_price: parseInt(newPrice)
            }
            const response = await sendPostRequest(data, 'change_device_selling_price/', process.env.REACT_APP_API_TOKEN);

            if (response?.success) {
                alert("Precio de equipo cambiado exitosamente.")
            } else if (!response?.success) {
                alert("Error: " + response.status)
            } else {
                alert("Ocurrio un problema intente mas tarde.")
            }
            setIsLoading(false);
        } catch (err) {
            alert(err.message)
        }
    }

    const increaseDeviceLimit = async () => {
        setIsLoading(true)
        if (!storeToChange || !limitIncrease) {
            alert("Por favor rellene todos los datos");
            setIsLoading(false);
            return;
        }
        try {
            const data = {
                store_name: storeToChange,
                device_limit_increase: parseInt(limitIncrease)
            }
            const response = await sendPostRequest(data, 'increase_device_limit/', process.env.REACT_APP_API_TOKEN);

            if (response?.success) {
                alert("Límite incrementado exitosamente")
            } else if (!response?.success) {
                alert("Error: " + response.status)
            } else {
                alert("Ocurrio un problema intente mas tarde.")
            }
            setIsLoading(false);
        } catch (err) {
            alert(err.message)
        }
    }

    const searchTransaction = async () => {
        try {

            if (!reportedAmount || !paymentDate || !referenceNumber || !deviceID2) {
                alert("Por favor rellene todos los datos")
                return;
            }
            setIsLoading(true);
            const data = {
                reference: referenceNumber.trim(),
                amount: convertToDotDecimal(reportedAmount),
                date: formatForAPI(paymentDate),
            }
            const response = await sendPostRequest(data, 'find_payment/', process.env.REACT_APP_API_TOKEN) // change for the righ enpoint name
            if (response?.success) {
                alert(response.status)
            } else if (!response?.success) {
                alert('Error: ' + response.status)
            } else {
                alert('Ocurrio un problema intente mas tarde.')
            }
            setIsLoading(false);
        } catch (err) {
            alert("Error: " + err.message)
        }
    }

    function convertToDotDecimal(str) {
        if (/^[+-]?(\d{1,3}(,\d{3})*|\d+)(,\d+)?$/.test(str)) {
            return str.replace(/,/g, ".");
        } else {
            return str;
        }
    }

    function formatForAPI(date) {
        // Format the date as yyyy/mm/dd
        const formattedDate = date.toLocaleString('en-CA', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            timeZone: 'America/New_York', // GMT-4 timezone (Eastern Time)
        }).replace(/\//g, '-'); // Replace slashes with dashes

        return formattedDate;
    }

    const users_cols = [
        { field: 'email', headerName: 'ID', width: 220, editable: false },
        { field: 'is_active', headerName: 'Activo', width: 200, editable: false },
        { field: 'registration_status', headerName: 'Estado de Registro', width: 220, editable: false },
        { field: 'max_loan_approved_3months', headerName: 'Monto Aprobado 3M', width: 220, editable: false },
        { field: 'max_loan_approved_6months', headerName: 'Monto Aprobado 6M', width: 220, editable: false },
        { field: 'identification_number', headerName: 'Cedula', width: 210, editable: false },
        { field: 'phone_number', headerName: '# de Telefono', width: 210, editable: false },
        { field: 'interest_rate', headerName: 'Interes', width: 210, editable: false },
    ];

    const payments_cols = [
        { field: 'id', headerName: 'ID', width: 200, editable: false },
        { field: 'user', headerName: 'Usuario', width: 200, editable: false },
        { field: 'is_active', headerName: 'Activo', width: 80, editable: false },
        { field: 'actualAmount', headerName: 'Monto a Pagar', width: 120, editable: false },
        { field: 'amountPaid', headerName: 'Monto Pagado', width: 120, editable: false },
        { field: 'dueDate', headerName: 'Fecha de Pago', width: 200, editable: false },
        { field: 'paid', headerName: 'Pagado', width: 80, editable: false },
        { field: 'checked_by_internal_team', headerName: 'Chequeado por Krece', width: 200, editable: false },
        { field: 'confirmationNumber', headerName: 'Confirmation Number', width: 300, editable: false },
        { field: 'amount_confirmed', headerName: 'Amount Confirmed', width: 300, editable: false },
    ];

    const transactions_cols = [
        { field: 'id', headerName: 'ID', width: 200, editable: false },
        { field: 'user', headerName: 'Usuario', width: 200, editable: false },
        { field: 'paid_to', headerName: 'Pagado a', width: 200, editable: false },
        { field: 'related_payment', headerName: 'Cuota Relacionada', width: 250, editable: false },
        { field: 'amount', headerName: 'Monto Pagado', width: 200, editable: false },
        { field: 'date', headerName: 'Fecha pagado', width: 200, editable: false },
        { field: 'confirmation_number', headerName: 'Referencia Cash', width: 250, editable: false },
        { field: 'reference', headerName: 'Referencia PagoMovil/Transf.', width: 250, editable: false },
    ];

    const devices_cols = [
        { field: 'id', headerName: 'ID', width: 200, editable: false },
        { field: 'date_bought', headerName: 'Fecha de compra', width: 200, editable: false },
        { field: 'device_imei_no', headerName: 'Imei', width: 200, editable: false },
        { field: 'nuovopay_device_id', headerName: 'ID de equipo', width: 120, editable: false },
        { field: 'device_management_provider', headerName: 'Tipo de bloqueo', width: 110, editable: false },
        { field: 'device_manufacturer', headerName: 'Marca', width: 110, editable: false },
        { field: 'device_model', headerName: 'Modelo', width: 180, editable: false },
        { field: 'device_price', headerName: 'Precio', width: 80, editable: false },
        { field: 'amount_financed', headerName: 'Monto Financiado', width: 80, editable: false },
        { field: 'user', headerName: 'Email del Comprador', width: 200, editable: false },
        { field: 'is_released', headerName: 'Liberado', width: 80, editable: false },
        { field: 'is_stolen', headerName: 'Robado', width: 110, editable: false },
        { field: 'device_paid_by_krece', headerName: 'Pagado por Krece', width: 140, editable: false },
        { field: 'seller_store', headerName: 'Tienda', width: 200, editable: false },
        { field: 'sub_store', headerName: 'Local', width: 200, editable: false },
    ];

    const pending_payments_cols = [
        { field: 'store_name', headerName: 'Store', width: 250, editable: false },
        { field: 'amount_financed', headerName: 'Amount Financed', width: 250, editable: false },
        { field: 'cash_in_store', headerName: 'Cash', width: 150, editable: false },
        { field: 'payments_due', headerName: 'Payment Due', width: 150, editable: false },
    ]

    // const runScript = async () => {
    //     try {
    //         setIsLoading(true);

    //         let lastDocument = null;
    //         const batchSize = 100;

    //         while (true) {
    //             console.log('starting new batch')
    //             let query = firestore
    //                 .collection('Users')
    //                 .where('registration_status', '==', 'device_enrolled')
    //                 .orderBy('__name__')
    //                 .limit(batchSize);

    //             if (lastDocument) {
    //                 query = query.startAfter(lastDocument);
    //             }

    //             const querySnapshot = await query.get();

    //             if (querySnapshot.empty) {
    //                 break; // Exit loop if no more documents
    //             }

    //             const batch = firestore.batch();
    //             const userUpdatePromises = [];

    //             querySnapshot.forEach(async (doc) => {
    //                 const data = doc.data();
    //                 const userRef = firestore.collection('Users').doc(doc.id);
    //                 const devicesQuery = firestore
    //                     .collection('Devices')
    //                     .where('user', '==', userRef)
    //                     .where('is_released', '==', false)
    //                     .limit(2);

    //                 const devSnapshot = await devicesQuery.get();

    //                 if (devSnapshot.size > 1) {
    //                     console.log(data.identification_number);
    //                     batch.update(userRef, { hasMultipleDevices: true });
    //                 }
    //             });

    //             await batch.commit();

    //             // Get the last document for pagination
    //             lastDocument = querySnapshot.docs[querySnapshot.docs.length - 1];
    //         }

    //         setIsLoading(false);
    //     } catch (error) {
    //         console.error('Error in script: ', error);
    //         setIsLoading(false);
    //     }
    // };

    // const getPendingPayments = async () => {
    //     try {
    //         const response = await sendGetRequest('pending_payments_by_store/', process.env.REACT_APP_API_TOKEN)
    //         if (response?.success) {
    //             const selfFinancedData = JSON.parse(response.data.self_financed_stores)
    //             const regularData = JSON.parse(response.data.non_self_financed_stores)
    //             const rows = [];
    //             var i = 0;
    //             for (let [key, value] of Object.entries(regularData.amount_financed)) {
    //                 rows.push({
    //                     id: i,
    //                     store_name: regularData.store_name[key],
    //                     amount_financed: value,
    //                     cash_in_store: regularData['cash in store'][key],
    //                     payments_due: regularData.payments_due[key]
    //                 })
    //                 i += 1;
    //             }
    //             setNonSelfFinancedStoresPayments(rows)
    //             const rows2 = [];
    //             for (let [key, value] of Object.entries(selfFinancedData.amount_financed)) {
    //                 rows2.push({
    //                     id: i,
    //                     store_name: selfFinancedData.store_name[key],
    //                     amount_financed: value,
    //                     cash_in_store: selfFinancedData['cash in store'][key],
    //                     payments_due: selfFinancedData.payments_due[key]
    //                 })
    //                 i += 1;
    //             }
    //             setSelfFinancedStoresPayments(rows2)
    //             setIsLoading(false);
    //         } else {
    //             alert("No se pudo cargar la data")
    //             setIsLoading(false);
    //         }

    //     } catch (error) {
    //         alert('Error: ' + error.message);
    //         setIsLoading(false);
    //     }
    // }

    const createMasterAccount = async () => {
        setIsLoading(true);
        if ((userData.country === 'venezuela' && clientType === '') || !storeCommercialName || !masterEmail) {
            alert('Por favor rellene todos los datos.')
            setIsLoading(false);
            return;
        }
        try {
            const data = {
                financed_by_krece: country !== 'venezuela' ? true : clientType,
                country: userData.country,
                store_name: storeCommercialName,
                master_store_email: masterEmail
            }

            const response = await sendPostRequest(data, 'storeprofile/masteruser/', process.env.REACT_APP_API_TOKEN)

            if (response?.success) {
                alert('Cuenta creada con exito!')
            } else if (!response?.success) {
                alert('Error: ' + response.status)
            } else {
                alert('Ocurrio un problema intente mas tarde.')
            }
            setIsLoading(false);
        } catch (err) {
            alert('Error: ' + err.message)
            setIsLoading(false);
        }
    }

    const changeCedula = async () => {
        setIsLoading(true);
        if (!newCedula || !oldCedula) {
            alert('Por favor rellene todos los datos.')
            setIsLoading(false);
            return;
        }
        try {
            const data = {
                identification_number: oldCedula.trim(),
                identification_number_new: newCedula.trim()
            }

            const response = await sendPostRequest(data, 'change_identification_number/', process.env.REACT_APP_API_TOKEN)

            if (response?.success) {
                alert('Cedula cambiada exitosamente')
            } else if (!response?.success) {
                alert('Error: ' + response.status)
            } else {
                alert('Ocurrio un problema intente mas tarde.')
            }
            setIsLoading(false);
        } catch (err) {
            alert('Error: ' + err.message)
            setIsLoading(false);
        }

    }

    const changeDeviceName = async () => {
        setIsLoading(true);
        if (!newDeviceName || !deviceID3) {
            alert('Por favor rellene todos los datos.');
            setIsLoading(false);
            return;
        }
        try {
            const data = {
                device_id: deviceID3,
                device_commercial_name: newDeviceName
            }
            const response = await sendPostRequest(data, 'change_device_commercial_name/', process.env.REACT_APP_API_TOKEN)

            if (response?.success) {
                alert(response.status)
            } else if (!response?.success) {
                alert('Error: ' + response.status)
            } else {
                alert('Ocurrio un problema intente mas tarde.')
            }
            setIsLoading(false);
        } catch (err) {
            alert('Error: ' + err.message)
            setIsLoading(false);
        }
    }

    const changePhoneNumber = async () => {
        setIsLoading(true);
        if (!cedula4 || !phoneNumber) {
            alert('Por favor rellene todos los datos.')
            setIsLoading(false);
            return;
        }
        const phoneRegex = /^\d{10,11}$/;
        if (!phoneRegex.test(phoneNumber)) {
            alert('Por favor ingrese un número de teléfono de 10 digitos.');
            setIsLoading(false);
            return;
        }
        try {
            const usersCollection = firestore.collection('Users');
            const queryUser = usersCollection
                .where('identification_number', '==', cedula4)
                .limit(1);
            await queryUser.get().then((querySnapshot) => {
                if (querySnapshot.docs.length === 0) {
                    alert("No existe un usuario con esta cedula.");
                    setIsLoading(false);
                    return;
                } else {
                    querySnapshot.forEach(async (doc) => {

                        if (doc.exists) {
                            doc.ref.update({
                                phone_number: phoneNumber
                            });
                            setIsLoading(false);
                            alert('Numero telefonico cambiado exitosamente.');
                            return;
                        } else {
                            alert("No existe un usuario con esta cedula.");
                            setIsLoading(false);
                            return;
                        }
                    })
                }
            }).catch((error) => {
                alert('Ocurrió un problema, intente de nuevo más tarde.')
                setIsLoading(false);
            });
        } catch {
            alert('Ocurrió un problema, intente de nuevo más tarde.')
            setIsLoading(false);
        }
    }

    const reportStolenDevice = async () => {
        setIsLoading(true);
        if (!cedula5) {
            alert('Por favor rellene todos los datos.')
            setIsLoading(false);
            return;
        }
        try {
            const data = {
                device_id: cedula5.trim()
            }
            const response = await sendPostRequest(data, 'report_device_stolen/', process.env.REACT_APP_API_TOKEN)
            if (response?.success) {
                alert('El equipo fue bloqueado por robo exitosamente.')
            } else if (!response?.success) {
                alert('Error: ' + response.status)
            } else {
                alert('Ocurrio un problema intente mas tarde.')
            }
            setIsLoading(false);
        } catch (err) {
            alert('Error: ' + err.message)
            setIsLoading(false);
        }
    }

    const unlockDevice = async () => {
        setIsLoading(true);
        if (!cedula6) {
            alert('Por favor rellene todos los datos.')
            setIsLoading(false);
            return;
        }
        try {
            const data = {
                device_id: cedula6.trim()
            }
            const response = await sendPostRequest(data, 'unlock_phone/', process.env.REACT_APP_API_TOKEN)

            if (response?.success) {
                alert('El equipo fue enviado a desbloquear exitosamente. Asegurese que el equipo tenga conexion a wifi o chip activo')
            } else if (!response?.success) {
                alert('Error: ' + response.status)
            } else {
                alert('Ocurrio un problema intente mas tarde.')
            }
            setIsLoading(false);
        } catch (err) {
            alert('Error: ' + err.message)
            setIsLoading(false);
        }
    }

    const freeDevice = async () => {
        setIsLoading(true);
        if (!devId2) {
            alert('Por favor rellene todos los datos.')
            setIsLoading(false);
            return;
        }
        try {
            const data = {
                device_id: devId2.trim()
            }
            const response = await sendPostRequest(data, 'release_device/', process.env.REACT_APP_API_TOKEN)

            if (response?.success) {
                alert('El equipo fue enviado a liberar exitosamente. Asegurese que el equipo tenga conexion a wifi o chip activo')
            } else if (!response?.success) {
                alert('Error: ' + response.status)
            } else {
                alert('Ocurrio un problema intente mas tarde.')
            }
            setIsLoading(false);
        } catch (err) {
            alert('Error: ' + err.message)
            setIsLoading(false);
        }
    }

    const changeFirstName = async () => {
        setIsLoading(true);
        if (!firstName || !cedula2) {
            alert('Por favor rellene todos los datos.')
            setIsLoading(false);
            return;
        }
        try {
            const data = {
                identification_number: cedula2,
                first_name: firstName.trim()
            }
            const response = await sendPostRequest(data, 'change_user_name/', process.env.REACT_APP_API_TOKEN)

            if (response?.success) {
                alert(response.status)
            } else if (!response?.success) {
                alert('Error: ' + response.status)
            } else {
                alert('Ocurrio un problema intente mas tarde.')
            }
            setIsLoading(false);
        } catch (err) {
            alert('Error: ' + err.message)
            setIsLoading(false);
        }
    }

    const changeLastName = async () => {
        setIsLoading(true);
        if (!lastName || !cedula2) {
            alert('Por favor rellene todos los datos.')
            setIsLoading(false);
            return;
        }
        try {
            const data = {
                identification_number: cedula2,
                last_name: lastName.trim()
            }
            const response = await sendPostRequest(data, 'change_user_name/', process.env.REACT_APP_API_TOKEN)

            if (response?.success) {
                alert(response.status)
            } else if (!response?.success) {
                alert('Error: ' + response.status)
            } else {
                alert('Ocurrio un problema intente mas tarde.')
            }
            setIsLoading(false);
        } catch (err) {
            alert('Error: ' + err.message)
            setIsLoading(false);
        }
    }

    const changeCreditLine = async () => {
        setIsLoading(true);
        if (!newCreditLine) {
            alert('Por favor rellene todos los datos.')
            setIsLoading(false);
            return;
        }
        try {
            const data = {
                identification_number: cedula4,
                nueva_linea_de_compra: parseInt(newCreditLine)
            }
            const response = await sendPostRequest(data, 'change_linea_de_compra/', process.env.REACT_APP_API_TOKEN)

            if (response?.success) {
                alert(response.status)
            } else if (!response?.success) {
                alert('Error: ' + response.status)
            } else {
                alert('Ocurrio un problema intente mas tarde.')
            }
            setIsLoading(false);
        } catch (err) {
            alert('Error: ' + err.message)
            setIsLoading(false);
        }
    }

    const resetPassword = async () => {
        setIsLoading(true);
        if (!email2) {
            alert('Por favor rellene todos los datos.')
            setIsLoading(false);
            return;
        }
        try {
            const data = {
                email: email2.trim()
            }
            const response = await sendPostRequest(data, 'change_user_password/', process.env.REACT_APP_API_TOKEN)

            if (response?.success) {
                alert(response.status)
            } else if (!response?.success) {
                alert('Error: ' + response.status)
            } else {
                alert('Ocurrio un problema intente mas tarde.')
            }
            setIsLoading(false);
        } catch (err) {
            alert('Error: ' + err.message)
            setIsLoading(false);
        }

    }

    const blackListUser = async () => { //#2A2E32_list_user , post. Input: identification_number
        setIsLoading(true);
        if (!cedula7) {
            alert('Por favor rellene todos los datos.')
            setIsLoading(false);
            return;
        }
        try {
            const data = {
                identification_number: cedula7.trim()
            }
            const response = await sendPostRequest(data, 'black_list_user/', process.env.REACT_APP_API_TOKEN)

            if (response?.success) {
                alert('El cliente fue agregado a la lista de clientes vetados por Krece.')
            } else if (!response?.success) {
                alert('Error: ' + response.status)
            } else {
                alert('Ocurrio un problema intente mas tarde.')
            }
            setIsLoading(false);
        } catch (err) {
            alert('Error: ' + err.message)
            setIsLoading(false);
        }
    }

    const createNewStore = async () => {
        setIsLoading(true);
        if (!masterStore || !kreceFee || !kreceFixedFee || !storeName || !instagram || !instagramLink || !whatsappLink || !logoUrl || !citySubStoreList || !hoursSubStoreList || !locationSubStoreList || !nameSubStoreList || !subStores) {
            alert('Por favor rellene todos los datos.');
            setIsLoading(false);
            return;
        }
        const cities = citySubStoreList.split(',')
        const hours = hoursSubStoreList.split(',')
        const locations = locationSubStoreList.split(',')
        const names = nameSubStoreList.split(',')

        const subStoresList = []
        await names.map((elm, idx) => {
            subStoresList.push({
                city: cities[idx].trim(),
                store_name: elm.trim(),
                location: locations[idx].trim(),
                hours_of_operation: hours[idx].trim()
            })
        });

        const storeInfo = {
            contact: {
                instagram: instagram.trim(),
                instagram_link: instagramLink.trim(),
                whatsapp_link: whatsappLink.trim(),
                phone_number: phoneNumber.trim()
            },
            logo_url: logoUrl.trim(),
            store_name: storeName.trim(),
            sub_stores: subStoresList
        }
        try {
            const data = {
                email: masterStore.trim(),
                krece_fee: convertToDotDecimal(kreceFee.trim()),
                krece_fixed_fee: convertToDotDecimal(kreceFixedFee.trim()),
                store_name: storeName.trim(),
                sub_stores_emails: subStores.split(', '),
                sub_stores: names,
                store_info: storeInfo
            }
            const response = await sendPostRequest(data, 'create_store_object/', process.env.REACT_APP_API_TOKEN)

            if (response?.success) {
                alert('La cuenta fue creada exitosamente')
            } else if (!response?.success) {
                alert('Error: ' + response.status)
            } else {
                alert('Ocurrio un problema intente mas tarde.')
            }
            setIsLoading(false);
        } catch (err) {
            alert('Error: ' + err.message)
            setIsLoading(false);
        }
    }

    const onStorePaymentClick = async () => {
        // await getPendingPayments();
        setShowStorePayments(true);
        setShowPaymentsToCheck(false);
        setShowKpis(false);
        setShowDbObjects(false);
    }

    const onCheckPaymentsClick = () => {
        setShowStorePayments(false);
        setShowPaymentsToCheck(true);
        setShowKpis(false);
        setShowDbObjects(false);
    }

    const onDbObjClick = () => {
        setShowStorePayments(false);
        setShowPaymentsToCheck(false);
        setShowKpis(false);
        setShowDbObjects(true);
    }

    const onKpisClick = () => {
        setShowStorePayments(false);
        setShowPaymentsToCheck(false);
        setShowKpis(true);
        setShowDbObjects(false);
    }

    if (isLoading) {
        return <Typography variant="h5" align="center" className={classes.title} style={{ marginTop: '2rem' }}>
            Cargando...
        </Typography>
    }


    return (
        <div className={classes.root}>
            <MenuButton showPaymentBut={true} showIcon={false} onItem1Click={onStorePaymentClick} label1={userData.is_admin || userData.access_type === 'ob' ? "Onboarding" : ""} onItem2Click={onCheckPaymentsClick} label2={userData.access_type === 'ata' ? "Data de clientes" : ""} onItem3Click={onDbObjClick} label3={userData.access_type === 'ata' ? "Acciones Administrativas" : ""} onItem4Click={onKpisClick} label4={userData.access_type === 'ata' ? "KPIs" : ""} />

            {showStorePayments && <div>
                <h2 className={classes.title2}>
                    Crear cuenta master
                </h2>
                <TextField
                    label="Correo cuenta master"
                    name="masterEmail"
                    value={masterEmail}
                    onChange={(e) => setMasterEmail(e.target.value)}
                    className={classes.textFields}
                    margin="normal"
                    fullWidth InputProps={{
                        style: {
                            color: 'white', // Text color
                            borderBottom: '1px solid white'
                        },
                    }}
                    InputLabelProps={{
                        style: {
                            color: '#D3D3D3', // Label color
                        },
                    }}
                />
                <TextField
                    label="Nombre del comercio"
                    name="storeCommercialName"
                    value={storeCommercialName}
                    onChange={(e) => setStoreCommercialName(e.target.value)}
                    className={classes.textFields}
                    margin="normal"
                    fullWidth InputProps={{
                        style: {
                            color: 'white', // Text color
                            borderBottom: '1px solid white'
                        },
                    }}
                    InputLabelProps={{
                        style: {
                            color: '#D3D3D3', // Label color
                        },
                    }}
                />
                {userData.country === 'venezuela' && <TextField
                    select
                    label="Tipo"
                    name="clientType"
                    value={clientType}
                    onChange={(e) => setClientType(e.target.value)}
                    className={classes.textFields}
                    margin="normal"
                    fullWidth InputProps={{
                        style: {
                            color: 'white', // Text color
                            borderBottom: '1px solid white'
                        },
                    }}
                    InputLabelProps={{
                        style: {
                            color: '#D3D3D3', // Label color
                        },
                    }}
                >
                    <MenuItem value={false}>Auto Financiado</MenuItem>
                    <MenuItem value={true}>Financiado por Krece</MenuItem>
                </TextField>}
                <Button
                    variant="contained"
                    className={classes.welcomeButtons}
                    onClick={createMasterAccount}
                    style={{ marginTop: '10px', marginLeft: 20 }}
                >
                    Crear Cuenta
                </Button>
                <h2 className={classes.title2}>
                    Wix Update
                </h2>
                <h2 className={classes.title}>
                    1. Exportar este CSV e importarlo en el collection Ciudades en Wix
                </h2>
                <Button
                    variant="contained"
                    className={classes.welcomeButtons}
                    onClick={exportCitiesCSV}
                    style={{ marginTop: '10px', marginLeft: 40 }}
                >
                    Exportar Ciudades.CSV
                </Button>
                <h2 className={classes.title}>
                    2. Exportar este CSV e importarlo en el collection Aliados en Wix
                </h2>
                <Button
                    variant="contained"
                    className={classes.welcomeButtons}
                    onClick={exportAliadosCSV}
                    style={{ marginTop: '10px', marginLeft: 40 }}
                >
                    Exportar Aliados.CSV
                </Button>
                <h2 className={classes.title}>
                    3. Ir a Wix y exportar el CSV generado de aliados e importarlo aqui
                </h2>
                <input type="file" accept=".csv" onChange={handleFileChange} style={{ marginLeft: 40, marginUp: 10, marginBottom: 20 }} />
                <h2 className={classes.title}>
                    4. Exportar este CSV e importarlo en el collection Tiendas en Wix
                </h2>
                <Button
                    variant="contained"
                    className={classes.welcomeButtons}
                    onClick={exportTiendasCSV}
                    style={{ marginTop: '10px', marginLeft: 40 }}
                >
                    Exportar Tiendas.CSV
                </Button>
            </div>}
            {showKpis && <div>
                <h2 className={classes.title} style={{ marginLeft: 20, fontSize: 24 }}>
                    KPIs
                </h2>
                <h2 className={classes.title} style={{ marginLeft: 20, fontSize: 20, marginTop: 30 }}>
                    Ventas del dia
                </h2>
                <Button
                    variant="contained"
                    className={classes.welcomeButtons}
                    onClick={getSalesKpi}
                    style={{ marginTop: '10px', marginLeft: 20 }}
                >
                    Obtener KPI
                </Button>
                {
                    salesSum > 0 && (
                        <span style={{ color: '#2A2E32', marginLeft: 30 }}>
                            {salesSum}
                        </span>
                    )
                }
                <h2 className={classes.title} style={{ marginLeft: 20, fontSize: 20, marginTop: 30 }}>
                    Cobranza
                </h2>
                {/* <TextField
                    select
                    label="Tipo"
                    name="historySearch"
                    value={historySearch}
                    onChange={(e) => setHistorySearch(e.target.value)}
                    className={classes.textFields}
                    margin="normal"
                    InputProps={{
                        style: {
                            color: 'white',
                            borderBottom: '1px solid white'
                        },
                    }}
                    InputLabelProps={{
                        style: {
                            color: '#D3D3D3',
                        },
                    }}
                    fullWidth InputProps={{
                            style: {
                                color: 'white', // Text color
                                borderBottom: '1px solid white'
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: '#D3D3D3', // Label color
                            },
                        }}
                >
                    <MenuItem value={'diario'}>Cobranza1</MenuItem>
                    <MenuItem value={'semanal'}>Cobranza2</MenuItem>
                </TextField> */}
                <Button
                    variant="contained"
                    className={classes.welcomeButtons}
                    onClick={getCobranzaKpi}
                    style={{ marginTop: '10px', marginLeft: 20 }}
                >
                    Obtener KPI
                </Button>
                {
                    showCobranzaKpi && (
                        <span style={{ color: '#2A2E32', marginLeft: 30 }}>
                            {cobranzaKpi}
                        </span>
                    )
                }
                {/* <div style={{ width: 600 }}>
                    <DataGrid
                        rows={kpi_rows}
                        columns={kpi_cols}
                        autoHeight
                        initialState={{
                            pagination: {
                                paginationModel: { page: 0, pageSize: 20 },
                            },
                        }}
                        editRowsModel={editRowsModel}
                        onEditRowsModelChange={handleEditRowsModelChange}
                        onCellEditCommit={handleCellEditCommit}
                        pageSizeOptions={[20, 20]}
                        getCellClassName={() => classes.cell}
                        sx={{
                            boxShadow: 2,
                            borderColor: '#2A2E32',
                            "& .MuiDataGrid-columnHeaders": { backgroundColor: "#2A2E32", color: "white" },
                            "& .MuiDataGrid-menuIconButton": { color: "white" },
                            "& .MuiDataGrid-sortIcon": { color: "white" }
                        }}
                    />
                </div> */}
            </div>}
            {showDbObjects && <div>
                <button onClick={() => screenShown === 'clientes' ? setScreenShown('') : setScreenShown('clientes')} style={{ display: 'flex', background: 'transparent', border: 'none', cursor: 'pointer', width: '100%', alignItems: 'center' }}>
                    <h2 className={classes.title2} style={{ marginTop: 40 }}>
                        Clientes
                    </h2>
                    <div style={{ justifyContent: 'center', alignItems: 'center', marginTop: 10, marginLeft: 20 }}>
                        {screenShown !== 'clientes' ? (
                            <FaChevronRight color="#FFFFFF80" size={20} />
                        ) : (
                            <FaChevronDown color="#FFFFFF80" size={20} />
                        )}
                    </div>
                </button>
                {screenShown === 'clientes' && <div style={{ marginLeft: 30 }}>
                    <h2 className={classes.title} style={{ marginTop: 20 }}>
                        Cambiar Email
                    </h2>
                    <TextField
                        label="Cedula del cliente"
                        name="cedula"
                        value={cedula}
                        onChange={(e) => setCedula(e.target.value)}
                        className={classes.textFields}
                        margin="normal"
                        fullWidth InputProps={{
                            style: {
                                color: 'white', // Text color
                                borderBottom: '1px solid white'
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: '#D3D3D3', // Label color
                            },
                        }}
                    />
                    <TextField
                        label="Nuevo email del cliente"
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className={classes.textFields}
                        margin="normal"
                        fullWidth InputProps={{
                            style: {
                                color: 'white', // Text color
                                borderBottom: '1px solid white'
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: '#D3D3D3', // Label color
                            },
                        }}
                    />
                    <Button
                        variant="contained"
                        className={classes.welcomeButtons}
                        onClick={changeUserEmail}
                        style={{ marginTop: '10px', marginLeft: 20 }}
                    >
                        Cambiar email
                    </Button>
                    <h2 className={classes.title} style={{ marginTop: 20 }}>
                        Cambio de contraseña
                    </h2>
                    <TextField
                        label="Email"
                        name="email2"
                        value={email2}
                        onChange={(e) => setEmail2(e.target.value)}
                        className={classes.textFields}
                        margin="normal"
                        fullWidth InputProps={{
                            style: {
                                color: 'white', // Text color
                                borderBottom: '1px solid white'
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: '#D3D3D3', // Label color
                            },
                        }}
                    />
                    <Button
                        variant="contained"
                        className={classes.welcomeButtons}
                        onClick={resetPassword}
                        style={{ marginTop: '10px', marginLeft: 20 }}
                    >
                        Resetear
                    </Button>
                    <h2 className={classes.title} style={{ marginTop: 20 }}>
                        Cambiar Cedula
                    </h2>
                    <TextField
                        label="Cedula incorrecta"
                        name="oldCedula"
                        value={oldCedula}
                        onChange={(e) => setOldCedula(e.target.value)}
                        className={classes.textFields}
                        margin="normal"
                        fullWidth InputProps={{
                            style: {
                                color: 'white', // Text color
                                borderBottom: '1px solid white'
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: '#D3D3D3', // Label color
                            },
                        }}
                    />
                    <TextField
                        label="Cedula correcta"
                        name="newCedula"
                        value={newCedula}
                        onChange={(e) => setNewCedula(e.target.value)}
                        className={classes.textFields}
                        margin="normal"
                        fullWidth InputProps={{
                            style: {
                                color: 'white', // Text color
                                borderBottom: '1px solid white'
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: '#D3D3D3', // Label color
                            },
                        }}
                    />
                    <Button
                        variant="contained"
                        className={classes.welcomeButtons}
                        onClick={changeCedula}
                        style={{ marginTop: '10px', marginLeft: 20 }}
                    >
                        Cambiar
                    </Button>
                    <h2 className={classes.title} style={{ marginTop: 20 }}>
                        Cambiar número de teléfono
                    </h2>
                    <TextField
                        label="Cedula"
                        name="cedula4"
                        value={cedula4}
                        onChange={(e) => setCedula4(e.target.value)}
                        className={classes.textFields}
                        margin="normal"
                        fullWidth InputProps={{
                            style: {
                                color: 'white', // Text color
                                borderBottom: '1px solid white'
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: '#D3D3D3', // Label color
                            },
                        }}
                    />
                    <TextField
                        label="Nuevo número"
                        name="phoneNumber"
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                        className={classes.textFields}
                        margin="normal"
                        fullWidth InputProps={{
                            style: {
                                color: 'white', // Text color
                                borderBottom: '1px solid white'
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: '#D3D3D3', // Label color
                            },
                        }}
                    />
                    <Button
                        variant="contained"
                        className={classes.welcomeButtons}
                        onClick={changePhoneNumber}
                        style={{ marginTop: '10px', marginLeft: 20 }}
                    >
                        Cambiar
                    </Button>
                    <h2 className={classes.title} style={{ marginTop: 20 }}>
                        Cambiar línea de compra
                    </h2>
                    <TextField
                        label="Cedula"
                        name="cedula4"
                        value={cedula4}
                        onChange={(e) => setCedula4(e.target.value)}
                        className={classes.textFields}
                        margin="normal"
                        fullWidth InputProps={{
                            style: {
                                color: 'white', // Text color
                                borderBottom: '1px solid white'
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: '#D3D3D3', // Label color
                            },
                        }}
                    />
                    <TextField
                        label="Nueva línea"
                        name="newCreditLine"
                        value={newCreditLine}
                        onChange={(e) => setNewCreditLine(e.target.value)}
                        className={classes.textFields}
                        margin="normal"
                        fullWidth InputProps={{
                            style: {
                                color: 'white', // Text color
                                borderBottom: '1px solid white'
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: '#D3D3D3', // Label color
                            },
                        }}
                    />
                    <Button
                        variant="contained"
                        className={classes.welcomeButtons}
                        onClick={changeCreditLine}
                        style={{ marginTop: '10px', marginLeft: 20 }}
                    >
                        Cambiar
                    </Button>
                    <h2 className={classes.title} style={{ marginTop: 20 }}>
                        Cambio de nombre
                    </h2>
                    <TextField
                        label="Cedula"
                        name="cedula2"
                        value={cedula2}
                        onChange={(e) => setCedula2(e.target.value)}
                        className={classes.textFields}
                        margin="normal"
                        fullWidth InputProps={{
                            style: {
                                color: 'white', // Text color
                                borderBottom: '1px solid white'
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: '#D3D3D3', // Label color
                            },
                        }}
                    />
                    <TextField
                        label="Nombre"
                        name="firstName"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        className={classes.textFields}
                        margin="normal"
                        fullWidth InputProps={{
                            style: {
                                color: 'white', // Text color
                                borderBottom: '1px solid white'
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: '#D3D3D3', // Label color
                            },
                        }}
                    />
                    <Button
                        variant="contained"
                        className={classes.welcomeButtons}
                        onClick={changeFirstName}
                        style={{ marginTop: '10px', marginLeft: 20 }}
                    >
                        cambiar
                    </Button>
                    <h2 className={classes.title} style={{ marginTop: 20 }}>
                        Cambio de apellido
                    </h2>
                    <TextField
                        label="Cedula"
                        name="cedula2"
                        value={cedula2}
                        onChange={(e) => setCedula2(e.target.value)}
                        className={classes.textFields}
                        margin="normal"
                        fullWidth InputProps={{
                            style: {
                                color: 'white', // Text color
                                borderBottom: '1px solid white'
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: '#D3D3D3', // Label color
                            },
                        }}
                    />
                    <TextField
                        label="Apellido"
                        name="lastName"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        className={classes.textFields}
                        margin="normal"
                        fullWidth InputProps={{
                            style: {
                                color: 'white', // Text color
                                borderBottom: '1px solid white'
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: '#D3D3D3', // Label color
                            },
                        }}
                    />
                    <Button
                        variant="contained"
                        className={classes.welcomeButtons}
                        onClick={changeLastName}
                        style={{ marginTop: '10px', marginLeft: 20 }}
                    >
                        cambiar
                    </Button>
                    <h2 className={classes.title} style={{ marginTop: 20 }}>
                        Agregar a lista de clientes vetados por Krece
                    </h2>
                    <TextField
                        label="Cedula"
                        name="cedula7"
                        value={cedula7}
                        onChange={(e) => setCedula7(e.target.value)}
                        className={classes.textFields}
                        margin="normal"
                        fullWidth InputProps={{
                            style: {
                                color: 'white', // Text color
                                borderBottom: '1px solid white'
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: '#D3D3D3', // Label color
                            },
                        }}
                    />
                    <Button
                        variant="contained"
                        className={classes.welcomeButtons}
                        onClick={blackListUser}
                        style={{ marginTop: '10px', marginLeft: 20 }}
                    >
                        Agregar
                    </Button>
                </div>}
                <button onClick={() => screenShown === 'equipos' ? setScreenShown('') : setScreenShown('equipos')} style={{ display: 'flex', background: 'transparent', border: 'none', cursor: 'pointer', width: '100%', alignItems: 'center' }}>
                    <h2 className={classes.title2} style={{ marginTop: 40 }}>
                        Equipos
                    </h2>
                    <div style={{ justifyContent: 'center', alignItems: 'center', marginTop: 10, marginLeft: 20 }}>
                        {screenShown !== 'equipos' ? (
                            <FaChevronRight color="#FFFFFF80" size={20} />
                        ) : (
                            <FaChevronDown color="#FFFFFF80" size={20} />
                        )}
                    </div>
                </button>
                {screenShown === 'equipos' && <div>
                    <h2 className={classes.title} style={{ marginTop: 20 }}>
                        Reportar celular como Robado
                    </h2>
                    <TextField
                        label="ID de equipo"
                        name="cedula5"
                        value={cedula5}
                        onChange={(e) => setCedula5(e.target.value)}
                        className={classes.textFields}
                        margin="normal"
                        fullWidth InputProps={{
                            style: {
                                color: 'white', // Text color
                                borderBottom: '1px solid white'
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: '#D3D3D3', // Label color
                            },
                        }}
                    />
                    <Button
                        variant="contained"
                        className={classes.welcomeButtons}
                        onClick={reportStolenDevice}
                        style={{ marginTop: '10px', marginLeft: 20 }}
                    >
                        Reportar
                    </Button>
                    <h2 className={classes.title} style={{ marginTop: 20 }}>
                        Desbloquear Celular
                    </h2>
                    <TextField
                        label="ID de equipo"
                        name="cedula6"
                        value={cedula6}
                        onChange={(e) => setCedula6(e.target.value)}
                        className={classes.textFields}
                        margin="normal"
                        fullWidth InputProps={{
                            style: {
                                color: 'white', // Text color
                                borderBottom: '1px solid white'
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: '#D3D3D3', // Label color
                            },
                        }}
                    />
                    <Button
                        variant="contained"
                        className={classes.welcomeButtons}
                        onClick={unlockDevice}
                        style={{ marginTop: '10px', marginLeft: 20 }}
                    >
                        Desbloquear
                    </Button>
                    <h2 className={classes.title} style={{ marginTop: 20 }}>
                        Liberar celular ya pagado
                    </h2>
                    <TextField
                        label="ID de equipo"
                        name="devId2"
                        value={devId2}
                        onChange={(e) => setDevId2(e.target.value)}
                        className={classes.textFields}
                        margin="normal"
                        fullWidth InputProps={{
                            style: {
                                color: 'white', // Text color
                                borderBottom: '1px solid white'
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: '#D3D3D3', // Label color
                            },
                        }}
                    />
                    <Button
                        variant="contained"
                        className={classes.welcomeButtons}
                        onClick={freeDevice}
                        style={{ marginTop: '10px', marginLeft: 20 }}
                    >
                        Liberar
                    </Button>
                    <h2 className={classes.title} style={{ marginTop: 20 }}>
                        Cambiar nombre comercial de equipo
                    </h2>
                    <TextField
                        label="ID de equipo"
                        name="deviceID3"
                        value={deviceID3}
                        onChange={(e) => setDeviceID3(e.target.value)}
                        className={classes.textFields}
                        margin="normal"
                        fullWidth InputProps={{
                            style: {
                                color: 'white', // Text color
                                borderBottom: '1px solid white'
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: '#D3D3D3', // Label color
                            },
                        }}
                    />
                    <TextField
                        label="Nuevo nombre"
                        name="newDeviceName"
                        value={newDeviceName}
                        onChange={(e) => setNewDeviceName(e.target.value)}
                        className={classes.textFields}
                        margin="normal"
                        fullWidth InputProps={{
                            style: {
                                color: 'white', // Text color
                                borderBottom: '1px solid white'
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: '#D3D3D3', // Label color
                            },
                        }}
                    />
                    <Button
                        variant="contained"
                        className={classes.welcomeButtons}
                        onClick={changeDeviceName}
                        style={{ marginTop: '10px', marginLeft: 20 }}
                    >
                        Cambiar
                    </Button>
                    {/* <h2 className={classes.title} style={{ marginTop: 20 }}>
                    Eliminar celular de cliente
                </h2>
                <TextField
                    label="Cedula del cliente"
                    name="cedula2"
                    value={cedula2}
                    onChange={(e) => setCedula2(e.target.value)}
                    className={classes.textFields}
                    margin="normal"
                    fullWidth InputProps={{
                        style: {
                            color: 'white', // Text color
                            borderBottom: '1px solid white'
                        },
                    }}
                    InputLabelProps={{
                        style: {
                            color: '#D3D3D3', // Label color
                        },
                    }}
                />
                <Button
                    variant="contained"
                    className={classes.welcomeButtons}
                    onClick={clearUserDevice}
                    style={{ marginTop: '10px', marginLeft: 20 }}
                >
                    Eliminar
                </Button> */}
                    <h2 className={classes.title} style={{ marginTop: 20 }}>
                        Cambiar precio de venta de equipo
                    </h2>
                    <TextField
                        label="ID del equipo"
                        name="devId"
                        type="tel"
                        value={devId}
                        onChange={(e) => setDevId(e.target.value)}
                        className={classes.textFields}
                        margin="normal"
                        fullWidth InputProps={{
                            style: {
                                color: 'white', // Text color
                                borderBottom: '1px solid white'
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: '#D3D3D3', // Label color
                            },
                        }}
                    />
                    <TextField
                        label="Nuevo Precio"
                        name="newPrice"
                        type="tel"
                        value={newPrice}
                        onChange={(e) => setNewPrice(e.target.value)}
                        className={classes.textFields}
                        margin="normal"
                        fullWidth InputProps={{
                            style: {
                                color: 'white', // Text color
                                borderBottom: '1px solid white'
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: '#D3D3D3', // Label color
                            },
                        }}
                    />
                    <Button
                        variant="contained"
                        className={classes.welcomeButtons}
                        onClick={changeDevicePrice}
                        style={{ marginTop: '10px', marginLeft: 20 }}
                    >
                        Cambiar Precio
                    </Button>
                </div>}
                <button onClick={() => screenShown === 'pagos' ? setScreenShown('') : setScreenShown('pagos')} style={{ display: 'flex', background: 'transparent', border: 'none', cursor: 'pointer', width: '100%', alignItems: 'center' }}>
                    <h2 className={classes.title2} style={{ marginTop: 40 }}>
                        Pagos
                    </h2>
                    <div style={{ justifyContent: 'center', alignItems: 'center', marginTop: 10, marginLeft: 20 }}>
                        {screenShown !== 'pagos' ? (
                            <FaChevronRight color="#FFFFFF80" size={20} />
                        ) : (
                            <FaChevronDown color="#FFFFFF80" size={20} />
                        )}
                    </div>
                </button>
                {screenShown === 'pagos' && <div>
                    <h2 className={classes.title} style={{ marginTop: 20 }}>
                        Buscar pago en Banco
                    </h2>
                    <TextField
                        label="Monto"
                        name="reportedAmount"
                        type="tel"
                        value={reportedAmount}
                        onChange={(e) => setReportedAmount(e.target.value)}
                        className={classes.textFields}
                        margin="normal"
                        fullWidth InputProps={{
                            style: {
                                color: 'white', // Text color
                                borderBottom: '1px solid white'
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: '#D3D3D3', // Label color
                            },
                        }}
                    />
                    <TextField
                        label="Referencia"
                        name="referenceNumber"
                        type="tel"
                        value={referenceNumber}
                        onChange={(e) => setReferenceNumber(e.target.value)}
                        className={classes.textFields}
                        margin="normal"
                        fullWidth InputProps={{
                            style: {
                                color: 'white', // Text color
                                borderBottom: '1px solid white'
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: '#D3D3D3', // Label color
                            },
                        }}
                    />
                    <TextField
                        label="Fecha de Pago"
                        name="paymentDate"
                        type="date"
                        value={paymentDate}
                        onChange={(e) => setPaymentDate(e.target.value)}
                        className={classes.textFields}
                        margin="normal"
                        fullWidth InputProps={{
                            style: {
                                color: 'white', // Text color
                                borderBottom: '1px solid white'
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: '#D3D3D3', // Label color
                            },
                        }}
                    />
                    <TextField
                        label="ID de producto"
                        name="deviceID2"
                        value={deviceID2}
                        onChange={(e) => setDeviceID2(e.target.value)}
                        className={classes.textFields}
                        margin="normal"
                        fullWidth InputProps={{
                            style: {
                                color: 'white', // Text color
                                borderBottom: '1px solid white'
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: '#D3D3D3', // Label color
                            },
                        }}
                    />
                    <Button
                        variant="contained"
                        className={classes.welcomeButtons}
                        onClick={searchTransaction}
                        style={{ marginTop: '10px', marginLeft: 20 }}
                    >
                        Buscar
                    </Button>
                    <h2 className={classes.title} style={{ marginTop: 20 }}>
                        Cambiar fecha de pagos a 15 y último
                    </h2>
                    <TextField
                        label="Cedula"
                        name="cedula3"
                        type="tel"
                        value={cedula3}
                        onChange={(e) => setCedula3(e.target.value)}
                        className={classes.textFields}
                        margin="normal"
                        fullWidth InputProps={{
                            style: {
                                color: 'white', // Text color
                                borderBottom: '1px solid white'
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: '#D3D3D3', // Label color
                            },
                        }}
                    />
                    <Button
                        variant="contained"
                        className={classes.welcomeButtons}
                        onClick={changePaymentDate15and30}
                        style={{ marginTop: '10px', marginLeft: 20 }}
                    >
                        Cambiar
                    </Button>
                    <h2 className={classes.title} style={{ marginTop: 20 }}>
                        Eliminar transacción
                    </h2>
                    <TextField
                        label="ID de transacción"
                        name="transactionId"
                        type="tel"
                        value={transactionId}
                        onChange={(e) => setTransactionId(e.target.value)}
                        className={classes.textFields}
                        margin="normal"
                        fullWidth InputProps={{
                            style: {
                                color: 'white', // Text color
                                borderBottom: '1px solid white'
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: '#D3D3D3', // Label color
                            },
                        }}
                    />
                    <Button
                        variant="contained"
                        className={classes.welcomeButtons}
                        onClick={deleteTransaction}
                        style={{ marginTop: '10px', marginLeft: 20 }}
                    >
                        Eliminar
                    </Button>
                </div>}
                <button onClick={() => screenShown === 'tiendas' ? setScreenShown('') : setScreenShown('tiendas')} style={{ display: 'flex', background: 'transparent', border: 'none', cursor: 'pointer', width: '100%', alignItems: 'center' }}>
                    <h2 className={classes.title2} style={{ marginTop: 40 }}>
                        Tiendas Aliadas
                    </h2>
                    <div style={{ justifyContent: 'center', alignItems: 'center', marginTop: 10, marginLeft: 20 }}>
                        {screenShown !== 'tiendas' ? (
                            <FaChevronRight color="#FFFFFF80" size={20} />
                        ) : (
                            <FaChevronDown color="#FFFFFF80" size={20} />
                        )}
                    </div>
                </button>
                {screenShown === 'tiendas' && <div>
                    <h2 className={classes.title} style={{ marginTop: 20 }}>
                        Subir limite de cupos
                    </h2>
                    <TextField
                        select
                        label="Aliado"
                        name="store"
                        value={storeToChange}
                        onChange={(e) => setStoreToChange(e.target.value)}
                        className={classes.textFields}
                        margin="normal"
                        fullWidth InputProps={{
                            style: {
                                color: 'white', // Text color
                                borderBottom: '1px solid white'
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: '#D3D3D3', // Label color
                            },
                        }}
                    >
                        <MenuItem value="ApplyMovil">ApplyMovil</MenuItem>
                        <MenuItem value="BitMercadoCityMarket">Bit Mercado City</MenuItem>
                        <MenuItem value="BitMercadoDigital">Bit Mercado Millennium/Marques</MenuItem>
                        <MenuItem value="77JMovilnet">77J</MenuItem>
                        <MenuItem value="TECNO_3107">Tecno 3107</MenuItem>
                        <MenuItem value="MobileShop">Mobile Shop</MenuItem>
                        <MenuItem value="GlobalMarket">Global Market</MenuItem>
                        <MenuItem value="GoStore">GoStore</MenuItem>
                    </TextField>
                    <TextField
                        label="Subir por:"
                        name="limitIncrease"
                        type="tel"
                        value={limitIncrease}
                        onChange={(e) => setLimitInrease(e.target.value)}
                        className={classes.textFields}
                        margin="normal"
                        fullWidth InputProps={{
                            style: {
                                color: 'white', // Text color
                                borderBottom: '1px solid white'
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: '#D3D3D3', // Label color
                            },
                        }}
                    />
                    <Button
                        variant="contained"
                        className={classes.welcomeButtons}
                        onClick={increaseDeviceLimit}
                        style={{ marginTop: '10px', marginLeft: 20 }}
                    >
                        Subir Límite
                    </Button>
                </div>}
                {/* <h2 className={classes.title} style={{ marginTop: 20 }}>
                    Crear nueva cuenta de aliado
                </h2>
                <TextField
                    label="Correo"
                    name="email"
                    value={masterStore}
                    onChange={(e) => setMasterStore(e.target.value)}
                    className={classes.textFields2}
                    margin="normal"
                    fullWidth InputProps={{
                        style: {
                            color: 'white', // Text color
                            borderBottom: '1px solid white'
                        },
                    }}
                    InputLabelProps={{
                        style: {
                            color: '#D3D3D3', // Label color
                        },
                    }}
                />
                <TextField
                    label="Comisión Krece %"
                    name="kreceFee"
                    value={kreceFee}
                    onChange={(e) => setKreceFee(e.target.value)}
                    className={classes.textFields2}
                    margin="normal"
                    fullWidth InputProps={{
                        style: {
                            color: 'white', // Text color
                            borderBottom: '1px solid white'
                        },
                    }}
                    InputLabelProps={{
                        style: {
                            color: '#D3D3D3', // Label color
                        },
                    }}
                />
                <TextField
                    label="Comisión Krece fija"
                    name="kreceFixedFee"
                    value={kreceFixedFee}
                    onChange={(e) => setKreceFixedFee(e.target.value)}
                    className={classes.textFields2}
                    margin="normal"
                    fullWidth InputProps={{
                        style: {
                            color: 'white', // Text color
                            borderBottom: '1px solid white'
                        },
                    }}
                    InputLabelProps={{
                        style: {
                            color: '#D3D3D3', // Label color
                        },
                    }}
                />
                <TextField
                    label="Nombre de aliado"
                    name="storeName"
                    value={storeName}
                    onChange={(e) => setStoreName(e.target.value)}
                    className={classes.textFields2}
                    margin="normal"
                    fullWidth InputProps={{
                        style: {
                            color: 'white', // Text color
                            borderBottom: '1px solid white'
                        },
                    }}
                    InputLabelProps={{
                        style: {
                            color: '#D3D3D3', // Label color
                        },
                    }}
                />
                <TextField
                    label="Instagram (ejemplo: @somoskrece)"
                    name="instagram"
                    value={instagram}
                    onChange={(e) => setInstagram(e.target.value)}
                    className={classes.textFields2}
                    margin="normal"
                    fullWidth InputProps={{
                        style: {
                            color: 'white', // Text color
                            borderBottom: '1px solid white'
                        },
                    }}
                    InputLabelProps={{
                        style: {
                            color: '#D3D3D3', // Label color
                        },
                    }}
                />
                <TextField
                    label="Instagram Link"
                    name="instagramLink"
                    value={instagramLink}
                    onChange={(e) => setInstagramLink(e.target.value)}
                    className={classes.textFields2}
                    margin="normal"
                    fullWidth InputProps={{
                        style: {
                            color: 'white', // Text color
                            borderBottom: '1px solid white'
                        },
                    }}
                    InputLabelProps={{
                        style: {
                            color: '#D3D3D3', // Label color
                        },
                    }}
                />
                <TextField
                    label="Whatsapp Link"
                    name="whatsappLink"
                    value={whatsappLink}
                    onChange={(e) => setWhatsappLink(e.target.value)}
                    className={classes.textFields2}
                    margin="normal"
                    fullWidth InputProps={{
                        style: {
                            color: 'white', // Text color
                            borderBottom: '1px solid white'
                        },
                    }}
                    InputLabelProps={{
                        style: {
                            color: '#D3D3D3', // Label color
                        },
                    }}
                />
                <TextField
                    label="#Teléfono"
                    name="phoneNumber"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    className={classes.textFields2}
                    margin="normal"
                    fullWidth InputProps={{
                        style: {
                            color: 'white', // Text color
                            borderBottom: '1px solid white'
                        },
                    }}
                    InputLabelProps={{
                        style: {
                            color: '#D3D3D3', // Label color
                        },
                    }}
                />
                <TextField
                    label="Logo Url"
                    name="logoUrl"
                    value={logoUrl}
                    onChange={(e) => setLogoUrl(e.target.value)}
                    className={classes.textFields2}
                    margin="normal"
                    fullWidth InputProps={{
                        style: {
                            color: 'white', // Text color
                            borderBottom: '1px solid white'
                        },
                    }}
                    InputLabelProps={{
                        style: {
                            color: '#D3D3D3', // Label color
                        },
                    }}
                />

                <TextField
                    label="Correos de Sucursales (introducir los correos separados por coma y espacio)"
                    name="subStores"
                    value={subStores}
                    onChange={(e) => setSubStores(e.target.value)}
                    className={classes.textFields2}
                    margin="normal"
                    fullWidth InputProps={{
                        style: {
                            color: 'white', // Text color
                            borderBottom: '1px solid white'
                        },
                    }}
                    InputLabelProps={{
                        style: {
                            color: '#D3D3D3', // Label color
                        },
                    }}
                />
                <TextField
                    label="Nombres de sucursales (Agregar separados por coma y espacio en mismo orden de arriba)"
                    name="nameSubStoreList"
                    value={nameSubStoreList}
                    onChange={(e) => setNameSubStoreList(e.target.value)}
                    className={classes.textFields2}
                    margin="normal"
                    fullWidth InputProps={{
                        style: {
                            color: 'white', // Text color
                            borderBottom: '1px solid white'
                        },
                    }}
                    InputLabelProps={{
                        style: {
                            color: '#D3D3D3', // Label color
                        },
                    }}
                />
                <TextField
                    label="Ciudades de sucursales (Agregar separados por coma y espacio en mismo orden de arriba)"
                    name="citySubStoreList"
                    value={citySubStoreList}
                    onChange={(e) => setCitySubStoreList(e.target.value)}
                    className={classes.textFields2}
                    margin="normal"
                    fullWidth InputProps={{
                        style: {
                            color: 'white', // Text color
                            borderBottom: '1px solid white'
                        },
                    }}
                    InputLabelProps={{
                        style: {
                            color: '#D3D3D3', // Label color
                        },
                    }}
                />
                <TextField
                    label="Horarios de sucursales (Agregar separados por coma y espacio en mismo orden de arriba)"
                    name="hoursSubStoreList"
                    value={hoursSubStoreList}
                    onChange={(e) => setHoursSubStoreList(e.target.value)}
                    className={classes.textFields2}
                    margin="normal"
                    fullWidth InputProps={{
                        style: {
                            color: 'white', // Text color
                            borderBottom: '1px solid white'
                        },
                    }}
                    InputLabelProps={{
                        style: {
                            color: '#D3D3D3', // Label color
                        },
                    }}
                />
                <TextField
                    label="Direcciónes de sucursales (Agregar separados por coma y espacio en mismo orden de arriba)"
                    name="locationSubStoreList"
                    value={locationSubStoreList}
                    onChange={(e) => setLocationSubStoreList(e.target.value)}
                    className={classes.textFields2}
                    margin="normal"
                    fullWidth InputProps={{
                        style: {
                            color: 'white', // Text color
                            borderBottom: '1px solid white'
                        },
                    }}
                    InputLabelProps={{
                        style: {
                            color: '#D3D3D3', // Label color
                        },
                    }}
                />

                <Button
                    variant="contained"
                    className={classes.welcomeButtons}
                    onClick={createNewStore}
                    style={{ marginTop: '10px', marginLeft: 20 }}
                >
                    Crear
                </Button> */}
                {/* <h2 className={classes.title} style={{ marginTop: 20 }}>
                    Correr Script
                </h2>
                <Button
                    variant="contained"
                    className={classes.welcomeButtons}
                    onClick={runScript}
                    style={{ marginTop: '10px', marginLeft: 20 }}
                >
                    Correr
                </Button> */}
            </div>}
            {
                showPaymentsToCheck && <div>
                    <h2 className={classes.title} style={{ marginTop: 20 }}>
                        Buscar data de cliente
                    </h2>
                    <TextField
                        select
                        label="Buscar por"
                        name="searchKeyType"
                        value={searchKeyType}
                        onChange={(e) => setSearchKeyType(e.target.value)}
                        className={classes.textFields}
                        margin="normal"
                        fullWidth InputProps={{
                            style: {
                                color: 'white', // Text color
                                borderBottom: '1px solid white'
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: '#D3D3D3', // Label color
                            },
                        }}
                    >
                        <MenuItem value="identification_number">Cedula</MenuItem>
                        <MenuItem value="email">Email</MenuItem>
                        <MenuItem value="phone_number">#Teléfono</MenuItem>
                        <MenuItem value="nuovopay_device_id">DeviceID</MenuItem>
                        <MenuItem value="imei_no">IMEI</MenuItem>
                        <MenuItem value="reference">Nro. de referencia</MenuItem>
                    </TextField>
                    <TextField
                        label={searchKeyType}
                        name="searchKey"
                        value={searchKey}
                        onChange={(e) => setSearchKey(e.target.value)}
                        className={classes.textFields}
                        margin="normal"
                        fullWidth InputProps={{
                            style: {
                                color: 'white', // Text color
                                borderBottom: '1px solid white'
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: '#D3D3D3', // Label color
                            },
                        }}
                    />
                    <Button
                        variant="contained"
                        className={classes.welcomeButtons}
                        onClick={searchClientData}
                        style={{ marginTop: '10px', marginLeft: 20 }}
                    >
                        Buscar
                    </Button>
                    {showUserData && <div>
                        {showUsers && <><h2 className={classes.title}>
                            Usuario
                        </h2>
                            <DataGrid
                                rows={users}
                                getRowId={(row) => row.identification_number}
                                columns={users_cols}
                                autoHeight
                                initialState={{
                                    pagination: {
                                        paginationModel: { page: 0, pageSize: 5 },
                                    },
                                }}
                                pageSizeOptions={[5, 10]}
                                getCellClassName={() => classes.cell}
                                sx={{
                                    boxShadow: 2,
                                    borderColor: '#2A2E32',
                                    "& .MuiDataGrid-columnHeaders": { backgroundColor: "#2A2E32", color: "white" },
                                    "& .MuiDataGrid-menuIconButton": { color: "white" },
                                    "& .MuiDataGrid-sortIcon": { color: "white" },
                                    "& .MuiTablePagination-root": {
                                        color: "white", // Change pagination text color
                                    },
                                    "& .MuiTablePagination-actions .MuiIconButton-root": {
                                        color: "white", // Change color of the pagination arrow buttons
                                    },
                                    "& .MuiTablePagination-actions .MuiSvgIcon-root": {
                                        fill: "white", // Change the fill color of the SVG icons (arrows)
                                    },
                                }}
                            /></>}
                        {devices.length > 0 && <><h2 className={classes.title}>
                            Equipos
                        </h2>
                            <DataGrid
                                rows={devices}
                                columns={devices_cols}
                                autoHeight
                                initialState={{
                                    pagination: {
                                        paginationModel: { page: 0, pageSize: 5 },
                                    },
                                }}
                                pageSizeOptions={[5, 10]}
                                getCellClassName={() => classes.cell}
                                sx={{
                                    boxShadow: 2,
                                    borderColor: '#2A2E32',
                                    "& .MuiDataGrid-columnHeaders": { backgroundColor: "#2A2E32", color: "white" },
                                    "& .MuiDataGrid-menuIconButton": { color: "white" },
                                    "& .MuiDataGrid-sortIcon": { color: "white" },
                                    "& .MuiTablePagination-root": {
                                        color: "white", // Change pagination text color
                                    },
                                    "& .MuiTablePagination-actions .MuiIconButton-root": {
                                        color: "white", // Change color of the pagination arrow buttons
                                    },
                                    "& .MuiTablePagination-actions .MuiSvgIcon-root": {
                                        fill: "white", // Change the fill color of the SVG icons (arrows)
                                    },
                                }}
                            />
                        </>}
                        {payments.length > 0 && <><h2 className={classes.title}>
                            Cuotas
                        </h2>
                            <DataGrid
                                rows={payments}
                                columns={payments_cols}
                                autoHeight
                                getCellClassName={() => classes.cell}
                                initialState={{
                                    pagination: {
                                        paginationModel: { page: 0, pageSize: 5 },
                                    },
                                }}
                                pageSizeOptions={[5, 10]}
                                sx={{
                                    boxShadow: 2,
                                    borderColor: '#2A2E32',
                                    "& .MuiDataGrid-columnHeaders": { backgroundColor: "#2A2E32", color: "white" },
                                    "& .MuiDataGrid-menuIconButton": { color: "white" },
                                    "& .MuiDataGrid-sortIcon": { color: "white" },
                                    "& .MuiTablePagination-root": {
                                        color: "white", // Change pagination text color
                                    },
                                    "& .MuiTablePagination-actions .MuiIconButton-root": {
                                        color: "white", // Change color of the pagination arrow buttons
                                    },
                                    "& .MuiTablePagination-actions .MuiSvgIcon-root": {
                                        fill: "white", // Change the fill color of the SVG icons (arrows)
                                    },
                                }}
                            /></>}
                        {transactions.length > 0 && <><h2 className={classes.title}>
                            Transacciones
                        </h2>
                            <DataGrid
                                rows={transactions}
                                columns={transactions_cols}
                                autoHeight
                                getCellClassName={() => classes.cell}
                                initialState={{
                                    pagination: {
                                        paginationModel: { page: 0, pageSize: 5 },
                                    },
                                }}
                                pageSizeOptions={[5, 10]}
                                sx={{
                                    boxShadow: 2,
                                    borderColor: '#2A2E32',
                                    "& .MuiDataGrid-columnHeaders": { backgroundColor: "#2A2E32", color: "white" },
                                    "& .MuiDataGrid-menuIconButton": { color: "white" },
                                    "& .MuiDataGrid-sortIcon": { color: "white" },
                                    "& .MuiTablePagination-root": {
                                        color: "white", // Change pagination text color
                                    },
                                    "& .MuiTablePagination-actions .MuiIconButton-root": {
                                        color: "white", // Change color of the pagination arrow buttons
                                    },
                                    "& .MuiTablePagination-actions .MuiSvgIcon-root": {
                                        fill: "white", // Change the fill color of the SVG icons (arrows)
                                    },
                                }}
                            /></>}
                    </div>}
                </div>
            }
        </div >
    );
};

export default AdminTable;
